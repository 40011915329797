/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeRequestApproveRequest } from './ChangeRequestApproveRequest';
export type ChangeRequestApproveRequests = {
    changeRequestType?: ChangeRequestApproveRequests.changeRequestType;
    authorizerName?: string;
    changeRequestApproveRequests?: Array<ChangeRequestApproveRequest>;
};
export namespace ChangeRequestApproveRequests {
    export enum changeRequestType {
        COMPETITOR = 'COMPETITOR',
        FRESHNESS = 'FRESHNESS',
        INSTRUCTION = 'INSTRUCTION',
    }
}

