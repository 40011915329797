import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import transEN from './en.json';
import transJP from './jp.json';

const resources = {
    en: { translation: transEN },
    jp: { translation: transJP },
};

i18next.use(initReactI18next).init({
    lng: 'jp', // if you're using a language detector, do not define the lng option
    debug: false,
    resources,
});
