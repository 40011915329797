import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setToastsError } from '../../../redux/actions/common';
import { setLoader } from '../../../redux/actions/api';
import { ExceptionControllerService, ProhibitedControllerService } from '../../../services';
import { getProhibitedCategoryTable } from '../../tables/table';
import Pagination from '../../common/Pagination';
import EditTable from '../../tables/EditTable';
import ProhibitedForm from './ProhibitedForm';
import apiErrorHandler from '../../../api/apiErrorHandler';

const DEFAULT_STATE = {
    departments: [],
    lines: [],
    classes: [],
    subclasses: [],
    selectedDivision: {},
    selectedDepartment: '',
    selectedLine: '',
    selectedClass: '',
    selectedSubclass: '',
    selectedLowestPriceType: 'COMPETITOR',

    page: 1,
    limit: 20,
    apiResponse: null,
    keyword: '',
    userOrganization: '',
};

const ProhibitedCategory = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isException } = props;
    const [state, setState] = useState(DEFAULT_STATE);

    const sort = useSelector((state) => state.sort);

    const {
        selectedDepartment,
        selectedLine,
        selectedClass,
        selectedSubclass,
        selectedLowestPriceType,

        page,
        limit,
        apiResponse,
    } = state;

    const table = getProhibitedCategoryTable();

    useEffect(() => {
        getCategories();
    }, [dispatch, page, sort]);

    const getCategories = () => {
        dispatch(setLoader(true));
        const requestData = {
            departmentCode: selectedDepartment,
            lineCode: selectedLine,
            classCode: selectedClass,
            subclassCode: selectedSubclass,
            lowestPriceType: selectedLowestPriceType,
            page: page,
            limit: limit,
            sortField: table.heads.find((head) => head.field === sort.sortField)?.field,
            sortType: sort.sortType,
        };
        const request = isException
            ? ExceptionControllerService.getExceptionCategories(requestData)
            : ProhibitedControllerService.getProhibitedCategories(requestData);
        request
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    apiResponse: response,
                }));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleClearForm = () => {
        setState(DEFAULT_STATE);
        getCategories();
    };

    return (
        <div className="price-request-competitive">
            <div className="okc-title">
                <div className="okc-title__wrapper fade-in">
                    <div className="okc-title">{isException ? '例外カテゴリー一覧・検索' : '禁止カテゴリー一覧・検索'}</div>
                    <a
                        className="btn btn--primary ml-2"
                        href={isException ? '/exception-category/register' : '/prohibited-category/register'}
                    >
                        <span className="btn-text">{t('prohibited_product_list.search_form.category_registration_button')}</span>
                    </a>
                </div>
            </div>
            <ProhibitedForm state={state} setState={setState} handleSubmit={getCategories} handleClear={handleClearForm} />
            <Pagination page={page} pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
            <EditTable isHeightTr heads={table.heads} body={table.body} dataTable={apiResponse?.data} />
        </div>
    );
};
export default ProhibitedCategory;
