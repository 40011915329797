import * as constant from '../constants/string';
export const getEndDate = (saleType, startDateString) => {
    let endDate;
    let date = new Date(startDateString);
    let day = 60 * 60 * 24 * 1000;
    switch (saleType) {
        case constant.SALE_TYPE.DAILY.name:
            endDate = new Date(date.getTime() + day);
            break;
        case constant.SALE_TYPE.MONTHLY.name:
            endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            break;
        case constant.SALE_TYPE.WEEKLY.name:
            endDate = new Date(date.getTime() + day * 7);
            break;
        case constant.SALE_TYPE.WEEKEND.name:
            let currentDay = date.getDay();
            if (currentDay === 6) {
                endDate = new Date(date.getTime() + day);
            } else if (currentDay === 0) {
                endDate = date;
            } else {
                endDate = new Date(date.getTime() + day * (7 - date.getDay()));
            }
            break;
        case constant.SALE_TYPE.OTHER.name:
            endDate = new Date(date.getTime() + day * 13);
            break;
        default:
            endDate = '';
    }

    return endDate;
};

export const convertTypeDate = function (date) {
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    day.length == 1 && (day = '0' + day);
    month.length == 1 && (month = '0' + month);
    return year + '/' + month + '/' + day;
};

export const arraysEqualIgnoreOrder = (arr1, arr2) => {
    // Check if lengths are different
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Sort both arrays and convert them to strings for easy comparison
    const sortedArr1 = arr1.slice().sort((a, b) => a - b);
    const sortedArr2 = arr2.slice().sort((a, b) => a - b);

    // Check if the sorted arrays are equal
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
