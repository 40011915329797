/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DivisionRequest } from './DivisionRequest';
export type PriceSearchRequest = {
    page?: number;
    limit?: number;
    sortField?: string;
    sortType?: PriceSearchRequest.sortType;
    divisions?: Array<DivisionRequest>;
    itemName?: string;
    jans?: Array<string>;
    itemCategory?: PriceSearchRequest.itemCategory;
    effectiveEndDate?: string;
    stopPurchaseFlag?: number;
    competitorChainCode?: string;
    competitorStoreCode?: string;
    changeRequestType?: PriceSearchRequest.changeRequestType;
};
export namespace PriceSearchRequest {
    export enum sortType {
        ASC = 'ASC',
        DESC = 'DESC',
    }
    export enum itemCategory {
        ALL = 'ALL',
        STANDARD_PRODUCT = 'STANDARD_PRODUCT',
        SPECIAL_OFFER = 'SPECIAL_OFFER',
        SEASONAL_CLASSIC = 'SEASONAL_CLASSIC',
        CUSTOMER_ORDER = 'CUSTOMER_ORDER',
        OTHER = 'OTHER',
    }
    export enum changeRequestType {
        COMPETITOR = 'COMPETITOR',
        FRESHNESS = 'FRESHNESS',
        INSTRUCTION = 'INSTRUCTION',
    }
}

