import '../../assets/css/pages/stores/department-select.css';
import DepartmentSelect from '../../components/layout/stores/DepartmentSelect';
import { useLocation } from 'react-router-dom';

const DepartmentSelectPage = () => {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const paramCompetitorStoreCode = query.get('competitorStoreCode');

    return (
        <div className="competitive-store">
            <DepartmentSelect paramCompetitorStoreCode={paramCompetitorStoreCode} />
        </div>
    );
};

export default DepartmentSelectPage;
