import moment from 'moment';

export const getUTCStringNow = () => {
    return moment().toDate().toISOString();
};

export const getDate = () => {
    return moment().toDate();
};

export const getDateNow = (toFormat = 'YYYY/MM/DD') => {
    return moment().local().format(toFormat);
};

export const formatTimestamp = (timestamp, toFormat = 'YYYY/MM/DD') => {
    if (timestamp) {
        return moment(timestamp).format(toFormat);
    }

    return '';
};

export const formatDate = (date, toFormat = 'YYYY/MM/DD') => {
    if (date) {
        return moment(date).format(toFormat);
    }

    return '';
};

export const formatTime = (time, toFormat = 'HH:mm') => {
    if (time) {
        return moment(time).format(toFormat);
    }
    return '';
};

export const formatFromTime = (time, fromFormat = 'HH:mm', toFormat = 'HH:mm') => {
    if (time) {
        return moment(time, fromFormat).format(toFormat);
    }

    return '';
};

export const formatToDate = (date, fromFormat = 'YYYY/MM/DD') => {
    if (date) {
        return moment(date, fromFormat).toDate();
    }

    return null;
};

export const formatToDateTime = (date, fromFormat = 'YYYY/MM/DD HH:mm') => {
    if (date) {
        return moment(date, fromFormat).toDate();
    }

    return null;
};

export const formatToDateOnly = (date, fromFormat = 'YYYY/MM/DD') => {
    if (date) {
        return moment(date, fromFormat).startOf('day').toDate();
    }

    return null;
};

export const isValid = (date, fromFormat = 'YYYY/MM/DD') => {
    if (date) {
        return moment(date, fromFormat).isValid();
    }

    return false;
};

export const isValidDate = (date) => {
    if (date) {
        return moment(date).isValid();
    }

    return false;
};
