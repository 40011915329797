/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CombinationInfo } from './CombinationInfo';
export type CompetitorCombinationCreateRequest = {
    storeCode?: string;
    competitorStoreCode?: string;
    distance?: CompetitorCombinationCreateRequest.distance;
    rank?: CompetitorCombinationCreateRequest.rank;
    notificationFlag?: boolean;
    combinationInfos?: Array<CombinationInfo>;
};
export namespace CompetitorCombinationCreateRequest {
    export enum distance {
        WITHIN_10_MINUTES_ON_FOOT = 'WITHIN_10_MINUTES_ON_FOOT',
        BICYCLE_WITHIN_10_MINUTES = 'BICYCLE_WITHIN_10_MINUTES',
        BICYCLE_WITHIN_30_MINUTES = 'BICYCLE_WITHIN_30_MINUTES',
        BICYCLE_30_MINUTES_OR_MORE = 'BICYCLE_30_MINUTES_OR_MORE',
        OTHERS = 'OTHERS',
    }
    export enum rank {
        TWICE_EVERY_WEEK = 'TWICE_EVERY_WEEK',
        ONCE_EVERY_WEEK = 'ONCE_EVERY_WEEK',
        ONCE_EVERY_TWO_WEEKS = 'ONCE_EVERY_TWO_WEEKS',
        ONCE_EVERY_MONTH = 'ONCE_EVERY_MONTH',
    }
}

