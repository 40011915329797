import { useState } from 'react';
import Card from '../../common/Card';
import Label from '../../common/Label';
import Button from '../../common/Button';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import ProhibitedForm from '../../layout/ITHeadquarters/ProhibitedForm';
import Checkbox from '../../common/Checkbox';
import { setLoader } from '../../../redux/actions/api';
import { ExceptionControllerService, ProhibitedControllerService } from '../../../services';
import apiErrorHandler from '../../../api/apiErrorHandler';

const ProhibitedCategoryRegister = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isException } = props;

    const DEFAULT_STATUS = {
        departments: [],
        lines: [],
        classes: [],
        subclasses: [],
        selectedDivision: {},
        selectedDepartment: '',
        selectedLine: '',
        selectedClass: '',
        selectedSubclass: '',
        deleteFlag: isException ? undefined : false,
        selectedLowestPriceType: 'COMPETITOR',
        selectedDeleteFlag: false,
    };

    const [state, setState] = useState(DEFAULT_STATUS);

    const { selectedDepartment, selectedLine, selectedClass, selectedSubclass, selectedLowestPriceType, selectedDeleteFlag } = state;

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmitForm = () => {
        const isValid = validateSubmitItem();
        if (isValid) return;

        dispatch(setLoader(true));
        const formData = {
            departmentCode: selectedDepartment,
            lineCode: selectedLine,
            classCode: selectedClass,
            subclassCode: selectedSubclass,
            lowestPriceType: selectedLowestPriceType,
            deleteFlag: selectedDeleteFlag,
        };
        const request = isException
            ? ExceptionControllerService.upsertExceptionCategory(formData)
            : ProhibitedControllerService.upsertProhibitedCategory(formData);
        request
            .then(() => {
                const title = isException ? '例外カテゴリー' : '禁止カテゴリー';
                dispatch(setToastsSuccess(title + 'を更新しました。'));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const validateSubmitItem = () => {
        if (selectedDepartment === '') {
            dispatch(setToastsError('部門を選択してください'));
            return true;
        }
    };

    return (
        <div className="okc-container prohibited-products">
            <div className="okc-inner">
                <div className="okc-title fade-in">{t('prohibited_product_registration.form.title')}</div>
            </div>
            <ProhibitedForm state={state} setState={setState} defaultStatus={DEFAULT_STATUS} />
            <div className="okc-card fade-in">
                <div className="okc-card__header">{isException ? '例外設定' : '禁止設定'}</div>
                <Card type="modal_card">
                    <div className="row okc-card__row">
                        <Label
                            type="column"
                            className="col-lg-2 mb-2 mb-lg-4"
                            text={t('exception_product_registration.setting.status.title')}
                        />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <div className="row m-0 js-okc-validate">
                                <Checkbox
                                    column="col-xl-3 col-lg-4 col-md-5"
                                    id="selected-delete-flag"
                                    label={'有効'}
                                    onChange={() => handleChangeState('selectedDeleteFlag', !selectedDeleteFlag)}
                                    value={!selectedDeleteFlag} // ’有効'に対してdeleteFlagのため反転
                                    checked={!selectedDeleteFlag}
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="d-flex c-slide-top mb-5">
                <Button className="btn btn--primary btn-block" id="registration-btn" onClick={handleSubmitForm}>
                    <span className="btn-text">{t('exception_product_registration.button.update')}</span>
                </Button>
            </div>
        </div>
    );
};
export default ProhibitedCategoryRegister;
