import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from './Input';
import Button from './Button';
import Choice from './Choice';
import '../../assets/css/common/table.css';
import '../../assets/css/common/common.css';
import { Link } from 'react-router-dom';
import SelectSearch from './SelectSearch';

const Table = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const {
        typeTable,
        handleClick,
        handleClickDelete,
        className,
        classNameTr,
        classNameFreezeFirst,
        classNameFreezeSecond,
        heads,
        body,
        dataTable = [],
        btn,
        isStickyColumn,
        onChangeCheckList,
        getListIdChecked,
        onChangePage,
        style,
        normal,
        onlyTab,
        confirm,
        handleClickSort,
        isSelectRow,
        onSelectRow,
        onDeleteRow,
        selectedRows,
        isMultiSelectRow,
        headerTitle,
        colSpan,
        onClickModal,
        isChildTable,
        checked,
        isCheckedBoxValue,
        setIsCheckedBoxValue,
        setIsShowModalConfirmRegister,
    } = props;
    const [state, setState] = useState({
        checkedValue: [],
        checkedConfirm: [],
        checkedApprove: [],
        checkedRemand: [],
        isScrollLeftEnd: true,
        click: true,
        checkbox_checked: true,
        checkbox_remand: true,
        unitData: '',
        statusChecked: [],
        isDisabledChecked: false,
        valueRemand: '',
    });
    const {
        checkedValue,
        checkedConfirm,
        checkedApprove,
        checkedRemand,
        isScrollLeftEnd,
        click,
        checkbox_checked,
        checkbox_remand,
        unitData,
        statusChecked,
        valueRemand,
    } = state;

    const tableWrapperRef = useRef();

    useImperativeHandle(ref, () => ({
        onClearCheckedList: () => {
            if (onChangeCheckList) {
                onChangeCheckList([]);
            }
            setState((prevState) => ({
                ...prevState,
                checkedValue: [],
            }));
        },
        handleSubmitModalConfirmRegister: () => {
            setIsCheckedBoxValue(statusChecked);
            setIsShowModalConfirmRegister(false);
            if (valueRemand) {
                if (checkedApprove.includes(valueRemand)) {
                    checkedApprove.splice(checkedApprove.indexOf(valueRemand), 1);
                }
                if (checkedConfirm.includes(valueRemand)) {
                    checkedConfirm.splice(checkedConfirm.indexOf(valueRemand), 1);
                } else {
                    checkedConfirm.push(valueRemand);
                }
                setCheckedCheckBox(checkedApprove, checkedRemand, checkedConfirm, [], []);
            }
        },
        handleCheckedByFormSubmit: (idRowChecked) => {
            if (!checkedApprove.includes(idRowChecked)) {
                checkedApprove.push(idRowChecked);
            }
            setCheckedCheckBox(checkedApprove, checkedRemand, checkedConfirm, [], []);
        },
    }));

    const handleOnChangeValue = (field, valueField) => {
        if (field === 'checkbox_checked') {
            handleCheckListApprove(valueField ? [] : ['all'], valueField, field);
        }

        if (field === 'checkbox_remand') {
            handleCheckListRemand(valueField ? [] : ['all'], valueField, field);
        }
    };

    const onClickSort = (isSort, filed, type) => {
        if (!isSort) {
            return;
        }
        handleClickSort(filed, type);
    };

    const handleCheckListApprove = (checked = [], valueField, field) => {
        const checkedValue = [];
        const checkedList = [];
        checkedRemand.length = 0;
        if (checked.length === 0) {
            checkedApprove.length = 0;
        } else {
            checked.forEach((value) => {
                if (value === 'all') {
                    checkedValue.push('all');
                    dataTable?.forEach((itemRow) => {
                        body?.columns?.forEach((itemColumn) => {
                            if (
                                itemColumn.isCheckbox &&
                                itemColumn?.field === field &&
                                !(checkedConfirm && checkedConfirm.includes(itemRow.id.toString()))
                            ) {
                                const item = itemRow[itemColumn?.field ?? ''][0].data[0];
                                if (!checkedApprove.includes(item?.value.toString())) {
                                    checkedApprove.push(item?.value.toString() ?? '');
                                }
                            }
                        });
                    });
                    checkedList.push('all');
                } else {
                    checkedValue.length = 0;
                    checkedApprove.length = 0;
                }
            });
        }

        setCheckedCheckBox(checkedApprove, checkedRemand, checkedConfirm, checkedValue, checkedList);

        setState((prevState) => ({
            ...prevState,
            checkbox_checked: valueField,
            checkbox_remand: checkbox_remand ? checkbox_remand : !valueField,
        }));
    };

    const handleCheckListRemand = (checked = [], valueField, field) => {
        const checkedValue = [];
        const checkedList = [];
        checkedApprove.length = 0;
        if (checked.length === 0) {
            checkedRemand.length = 0;
        } else {
            checked.forEach((value) => {
                if (value === 'all') {
                    checkedValue.push('all');
                    dataTable?.forEach((itemRow) => {
                        body?.columns?.forEach((itemColumn) => {
                            if (itemColumn.isCheckbox && itemColumn?.field === field) {
                                const item = itemRow[itemColumn?.field ?? ''][0].data[0];
                                if (!checkedRemand.includes(item?.value.toString())) {
                                    checkedRemand.push(item?.value.toString() ?? '');
                                }
                            }
                        });
                    });
                    checkedList.push('all');
                } else {
                    checkedValue.length = 0;
                    checkedRemand.length = 0;
                }
            });
        }

        setCheckedCheckBox(checkedApprove, checkedRemand, checkedConfirm, checkedValue, checkedList);

        setState((prevState) => ({
            ...prevState,
            checkbox_checked: checkbox_checked ? checkbox_checked : !valueField,
            checkbox_remand: valueField,
        }));
    };

    const handleCheckList = (checked = [], field, eventValue) => {
        const checkedList = [];
        const checkedValue = [];
        setState((prevState) => ({
            ...prevState,
            valueRemand: '',
        }));

        if (field === 'checkbox_checked') {
            if (checkedApprove.includes(eventValue)) {
                checkedApprove.splice(checkedApprove.indexOf(eventValue), 1);
            } else {
                if (checkedRemand.includes(eventValue)) {
                    checkedRemand.splice(checkedRemand.indexOf(eventValue), 1);
                }
                checkedApprove.push(eventValue);
            }
        }

        if (field === 'checkbox_remand') {
            if (checkedRemand.includes(eventValue)) {
                checkedRemand.splice(checkedRemand.indexOf(eventValue), 1);
            } else {
                if (checkedApprove.includes(eventValue)) {
                    checkedApprove.splice(checkedApprove.indexOf(eventValue), 1);
                }
                checkedRemand.push(eventValue);
            }
        }

        if (field === 'checkbox_show_modal') {
            setIsShowModalConfirmRegister(true);
            setState((prevState) => ({
                ...prevState,
                statusChecked: checked,
                valueRemand: eventValue,
            }));
        }

        const allCheckIndex = checked.indexOf('all');
        if (checkedApprove.length === (dataTable?.length ?? 0)) {
            if (allCheckIndex < 0) {
                checkedValue?.push('all');
                checkedList?.push('all');
            }
            setState((prevState) => ({
                ...prevState,
                checkbox_checked: false,
                checkbox_remand: true,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                checkbox_checked: true,
            }));
        }

        if (checkedRemand.length === (dataTable?.length ?? 0)) {
            if (allCheckIndex < 0) {
                checkedValue?.push('all');
                checkedList?.push('all');
            }
            setState((prevState) => ({
                ...prevState,
                checkbox_checked: true,
                checkbox_remand: false,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                checkbox_remand: true,
            }));
        }

        setCheckedCheckBox(checkedApprove, checkedRemand, checkedConfirm, checkedValue, checkedList);
    };

    const setCheckedCheckBox = (checkedApprove, checkedRemand, checkedConfirm, checkedValue, checkedList) => {
        checkedApprove.forEach((value) => {
            checkedValue?.push('approve_' + value);
            checkedList?.push('approve_' + value);
        });

        checkedRemand.forEach((value) => {
            checkedValue?.push('remand_' + value);
            checkedList.push('remand_' + value);
        });

        checkedConfirm.forEach((value) => {
            checkedValue?.push('confirm_' + value);
            checkedList.push('confirm_' + value);
        });

        if (onChangeCheckList) {
            onChangeCheckList(checkedList);
            let idChecked = [];
            checkedList.forEach((item) => {
                let id = item.split('_')[1];
                if (id && !idChecked.includes(id)) {
                    idChecked.push(id);
                }
            });
            if (getListIdChecked) {
                getListIdChecked(idChecked);
            }
        }

        setState((prevState) => ({
            ...prevState,
            checkedValue,
        }));
    };

    const handleScrollHorizontal = () => {
        if (isStickyColumn) {
            setState((prevState) => ({
                ...prevState,
                isScrollLeftEnd: tableWrapperRef.current?.scrollLeft === 0,
            }));
        }
    };
    const handleChanePage = (page) => {
        setState((prevState) => ({
            ...prevState,
            page,
        }));
        if (onChangePage) {
            onChangePage(page);
        }
    };
    const handleSelectRow = (isSelectRow, index, record) => {
        if (!isSelectRow || !checkEnableSelect(record)) {
            return;
        }
        let unitData = record.fixedPrice + '+' + record.unitCode + '_' + record.selectedCapacity2;
        setState((prevState) => ({
            ...prevState,
            unitData: unitData,
        }));
        onSelectRow(index, record.id);
    };

    const handleDeleteRow = (indexRow) => {
        onDeleteRow(indexRow);
    };

    const renderHeads = () => {
        return (
            <>
                {confirm && (
                    <tr>
                        <th className="invisible column" colSpan={heads && heads.length > 1 ? heads.length - 3 : 1}></th>
                        <th className={`checkbox freeze-column ${classNameFreezeFirst}`} style={{ padding: '0px' }}>
                            <button
                                className={`btn confirm-registration__all-set ${
                                    checkedValue.includes('all') && !checkbox_checked ? 'btn--secondary' : 'btn--primary'
                                }`}
                                onClick={() => handleOnChangeValue('checkbox_checked', checkbox_checked ? false : true)}
                            >
                                {checkedValue.includes('all') && !checkbox_checked ? t('button.release_all') : t('button.check_all')}
                            </button>
                        </th>
                        <th className={`checkbox freeze-column ${classNameFreezeSecond}`} style={{ padding: '0px' }}>
                            <button
                                className={`btn confirm-registration__all-remand ${
                                    checkedValue.includes('all') && !checkbox_remand ? 'btn--secondary' : 'btn--primary'
                                }`}
                                onClick={() => handleOnChangeValue('checkbox_remand', checkbox_remand ? false : true)}
                            >
                                {checkedValue.includes('all') && !checkbox_remand ? t('button.release_all') : t('button.check_all')}
                            </button>
                        </th>
                        <th className="invisible"></th>
                    </tr>
                )}
                {normal && (
                    <tr>
                        <th className="invisible column" colSpan={heads && heads.length > 1 ? heads.length - 1 : 1}></th>
                        <th className="checkbox freeze-column freeze-right-0" style={{ padding: '0px' }}>
                            <button
                                className={`btn price-request__all ${checkedValue.includes('all') ? 'btn--secondary' : 'btn--primary'}`}
                                onClick={() => handleOnChangeValue('checkbox_checked', checkbox_checked ? false : true)}
                            >
                                {checkedValue.includes('all') ? t('button.release_all') : t('button.check_all')}
                            </button>
                        </th>
                    </tr>
                )}
                {onlyTab && (
                    <tr>
                        <th className="invisible column" colSpan={heads && heads.length > 1 ? heads.length - 1 : 1}></th>
                    </tr>
                )}
                {headerTitle && (
                    <tr>
                        <th className="title v-header-title" colSpan={colSpan}>
                            {headerTitle}
                        </th>
                    </tr>
                )}
                <tr className={classNameTr}>
                    {heads?.map((head, index) => {
                        if (Object.keys(head).length === 0) {
                            return;
                        }

                        return (
                            <th
                                key={index}
                                colSpan={head.colSpan}
                                className={`v-select-default ${head.isSort ? 'sort' : ''} ${head.type === 'DESC' ? 'active' : ''} ${
                                    head.className
                                }`}
                                onClick={() => onClickSort(head.isSort, head.field, head.type === 'DESC' ? 'ASC' : 'DESC')}
                            >
                                <>{head?.title}</>
                            </th>
                        );
                    })}
                    {isChildTable && <th className="v-base_minWidth--53 v-base_width--2 cell-border-left">削除</th>}
                </tr>
            </>
        );
    };

    const renderRowValue = (itemColumn, itemRow) => {
        let rowValue = null;
        const fields = itemColumn?.field?.split('.') ?? [];

        for (const field of fields) {
            rowValue = !rowValue ? itemRow[field ?? ''] : rowValue[field];
        }

        if (rowValue) {
            if (itemColumn?.mappingData) {
                return itemColumn?.mappingData.find((item) => item.value === rowValue)?.label ?? rowValue;
            }
            return rowValue;
        } else {
            return '-';
        }
    };

    const checkSelectedRow = (id) => {
        let isSelectedRow = false;
        if (!selectedRows || selectedRows.length === 0) {
            return false;
        }
        selectedRows.forEach((itemRow) => {
            if (itemRow.id === id) {
                isSelectedRow = true;
            }
        });
        return isSelectedRow;
    };

    const checkEnableSelect = (itemRowSelect) => {
        if (!selectedRows || selectedRows.length === 0 || isMultiSelectRow) {
            return true;
        }
        let calculatedUnit = itemRowSelect.fixedPrice + '+' + itemRowSelect.unitCode + '_' + itemRowSelect.selectedCapacity2;
        return unitData === calculatedUnit;
    };

    const renderRows = () => {
        return (
            <>
                {dataTable.length > 0 ? (
                    <>
                        {dataTable?.map((itemRow, indexRow) => (
                            <tr
                                key={indexRow}
                                className={`${classNameTr} ${isSelectRow ? 'v-select-row' : 'v-select-default'} ${
                                    isSelectRow && checkSelectedRow(itemRow.id) ? 'selectable active' : ''
                                } ${checkEnableSelect(itemRow) ? '' : 'unselectable'}`}
                                onClick={() => {
                                    handleSelectRow(isSelectRow, indexRow, itemRow);
                                }}
                            >
                                {body?.columns?.map((itemColumn, indexColumn) => {
                                    if (Object.keys(itemColumn).length === 0) {
                                        return <td key={indexColumn}></td>;
                                    }
                                    return (
                                        <td
                                            key={indexColumn}
                                            className={`${itemColumn.isButton ? 'components__table-btn_body' : ''} ${
                                                itemColumn?.isBorder ? 'cell-border' : ''
                                            } ${itemColumn?.className ?? ''}`}
                                        >
                                            {itemColumn.isInput ? (
                                                itemRow[itemColumn?.field ?? ''] && (
                                                    <div className="form-textarea">
                                                        <Input
                                                            value={itemRow[itemColumn?.field ?? ''][0].value}
                                                            name={itemRow[itemColumn?.field ?? ''][0].name}
                                                            id={itemRow[itemColumn?.field ?? ''][0].id}
                                                            disabled={checkedApprove.includes(itemRow.id.toString())}
                                                            type={itemRow[itemColumn?.field ?? ''][0].type}
                                                            className={itemRow[itemColumn?.field ?? ''][0].className}
                                                            onChange={itemRow[itemColumn?.field ?? ''][0].onChange}
                                                            onBlur={itemRow[itemColumn?.field ?? ''][0].onBlur}
                                                            onPress={itemRow[itemColumn?.field ?? ''][0].onPress}
                                                            fontSize={itemRow[itemColumn?.field ?? ''][0].fontSize}
                                                            placeholder={itemRow[itemColumn?.field ?? ''][0].placeholder}
                                                            maxLength={itemRow[itemColumn?.field ?? ''][0].maxLength}
                                                        />
                                                    </div>
                                                )
                                            ) : itemColumn.isCheckbox ? (
                                                <Choice
                                                    type="checkbox"
                                                    checked={checkedValue}
                                                    data={itemRow[itemColumn?.field ?? ''][0].data}
                                                    className="justify-content-center"
                                                    onChange={(value, eventValue) => handleCheckList(value, itemColumn?.field, eventValue)}
                                                    name={itemRow[itemColumn?.field ?? ''][0].data[0].name}
                                                    labelCheck={itemColumn?.label}
                                                    disabled={
                                                        (checkedConfirm.includes(itemRow.id.toString()) &&
                                                            itemColumn?.field === 'checkbox_checked') ||
                                                        (itemRow.imperfectFlg && itemColumn?.field === 'checkbox_checked')
                                                    }
                                                />
                                            ) : itemColumn.isCheckboxShowModal ? (
                                                <Choice
                                                    type="checkbox"
                                                    checked={isCheckedBoxValue}
                                                    isChecked={itemRow.imperfectFlg}
                                                    data={itemRow[itemColumn?.field ?? ''][0].data}
                                                    className="justify-content-center"
                                                    onChange={(value, eventValue) => handleCheckList(value, itemColumn?.field, eventValue)}
                                                    name={itemRow[itemColumn?.field ?? ''][0].data[0].name}
                                                    labelCheck={itemColumn?.label}
                                                />
                                            ) : itemColumn.isImperfectFlg ? (
                                                <Choice
                                                    type="checkbox"
                                                    isChecked={itemRow.imperfectFlg}
                                                    disabled={itemColumn.disabled}
                                                    isAlwayDisable={true}
                                                    data={itemRow[itemColumn?.field ?? ''][0].data}
                                                    className="justify-content-center"
                                                    name={itemRow[itemColumn?.field ?? ''][0].data[0].name}
                                                    labelCheck={itemColumn?.label}
                                                />
                                            ) : itemColumn.deleteFlg ? (
                                                <Choice
                                                    type="checkbox"
                                                    checked={!!itemRow[itemColumn?.field ?? ''][0].data[0].checked}
                                                    disabled={itemColumn.disabled}
                                                    data={itemRow[itemColumn?.field ?? ''][0].data}
                                                    className="justify-content-center"
                                                    name={itemRow[itemColumn?.field ?? ''][0].data[0].name}
                                                    labelCheck={itemColumn?.label}
                                                />
                                            ) : itemColumn.isBooleanCheckbox ? (
                                                <Choice
                                                    type="checkbox"
                                                    checked={!!itemRow[itemColumn?.field ?? ''][0].data[0].checked}
                                                    data={itemRow[itemColumn?.field ?? ''][0].data}
                                                    // className="justify-content-center"
                                                    disabled={itemColumn.disabled}
                                                    name={itemRow[itemColumn?.field ?? ''][0].data[0].name}
                                                    labelCheck={itemColumn?.label}
                                                    onChange={(value, eventValue) => handleCheckList(value, itemColumn?.field, eventValue)}
                                                />
                                            ) : itemColumn.isSelectSearch ? (
                                                // <div className={itemColumn.divClass ? itemColumn.divClass : ''}>
                                                <div className="select2-selection select2-selection--single">
                                                    <SelectSearch
                                                        value={itemRow[itemColumn?.field ?? ''][0].value}
                                                        placeholder={itemRow[itemColumn?.field ?? ''][0].placeholder}
                                                        onChange={itemRow[itemColumn?.field ?? ''][0].onChange}
                                                        options={itemRow[itemColumn?.field ?? ''][0].options}
                                                        style={itemRow[itemColumn?.field ?? ''][0].style}
                                                    />
                                                </div>
                                            ) : itemColumn.isButton ? (
                                                <Button
                                                    startIcon={itemRow[itemColumn?.field ?? '']['srcIcon']}
                                                    className="components__table-btn w-100"
                                                    background={itemRow[itemColumn?.field ?? '']['background'] ?? 'green'}
                                                    onClick={itemRow[itemColumn?.field ?? '']['onClick'] ?? {}}
                                                />
                                            ) : itemColumn.isButtonDelete ? (
                                                <button
                                                    className="btn btn--icon btn--secondary confirm-delete-modal"
                                                    onClick={(e) => handleClickDelete(e)}
                                                >
                                                    <i className="icon-bin"></i>
                                                    <span className="btn-text"></span>
                                                </button>
                                            ) : itemColumn.isButtonEdit ? (
                                                <Button
                                                    className="btn btn--icon btn--primary confirm-delete-modal"
                                                    onClick={(e) => handleClick(indexRow, itemRow)}
                                                >
                                                    <i className="icon-edit"></i>
                                                    <span className="btn-text"></span>
                                                </Button>
                                            ) : (
                                                <span className={itemColumn?.isLink ? 'components__table-link' : ''}>
                                                    {renderRowValue(itemColumn, itemRow)}
                                                </span>
                                            )}
                                        </td>
                                    );
                                })}
                                {isChildTable && (
                                    <td className="v-base_minWidth--53 v-base_width--2 cell-border-left">
                                        <button
                                            onClick={() => handleDeleteRow(indexRow)}
                                            className="btn btn--icon btn--secondary confirm-delete-modal"
                                        >
                                            <i className="icon-bin"></i>
                                            <span className="btn-text"></span>
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </>
                ) : (
                    <>
                        <tr className="no-result">
                            <td colSpan={heads?.length || 1}>該当商品が見つかりません。</td>
                        </tr>
                    </>
                )}
            </>
        );
    };

    const handleShowModal = (systemRank, storeRank) => {
        onClickModal(systemRank, storeRank);
    };

    const getStoreRank = (storeRank) => {
        const STORE_RANK = { 0: '週2回〜', 1: '週1回', 2: '隔週', 3: '月1回' };
        return STORE_RANK[storeRank];
    };

    if (typeTable === 'survey') {
        return (
            <table className={`okc-table__main ${className}`} id="survey-history-table" style={style}>
                <thead>
                    <tr>
                        {heads.map((itemHeader, index) => (
                            <th key={index}>{itemHeader.name}</th>
                        ))}
                    </tr>
                </thead>
                {body.rows.map((itemRow, index) => (
                    <tbody key={index}>
                        <tr className="no-result">
                            <th rowSpan="2">
                                <div className="d-flex">
                                    <Link to={`/select-store?competitorStoreMasterId=${itemRow?.competitorStoreMasterId}`}>
                                        {itemRow.competitorName}
                                    </Link>
                                    <Link className="link" to={itemRow.googleDriverUrl} target="_blank">
                                        <i className="icon-drive"></i>
                                    </Link>
                                </div>
                            </th>
                            <td
                                className={`modal-link frequency ${itemRow.highlight ? 'highlight' : ''}`}
                                rowSpan="2"
                                data-toggle="modal"
                                onClick={() => handleShowModal(itemRow?.systemRank, itemRow?.surveyFrequencyUnitWeek)}
                            >
                                {getStoreRank(itemRow.surveyFrequencyUnitWeek)}
                            </td>
                            <td className={`${itemRow.highlight ? 'highlight' : ''}`} rowSpan="2">
                                {itemRow?.uninvestigatedDays}
                            </td>
                            {itemRow?.surveyList.map((item, index) => {
                                if (item.requesterDate != null) {
                                    return (
                                        <td key={index} className={`${itemRow.highlight ? 'highlight' : ''}`}>
                                            {item.requesterDate}
                                        </td>
                                    );
                                } else {
                                    return (
                                        <td className="empty" rowSpan="2" key={index}>
                                            ー
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                        <tr>
                            {itemRow?.surveyList.map((item, index) => {
                                if (item.requesterName != null) {
                                    return (
                                        <td key={index} className={`${itemRow.highlight ? 'highlight' : ''}`}>
                                            {item.requesterName}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    </tbody>
                ))}
            </table>
        );
    }

    return (
        <div>
            <div className="d-flex align-items-center bases__margin--bottom12">{btn}</div>
            <div
                ref={tableWrapperRef}
                className={`okc-table ${className} components__table-wrapper bases__padding--bottom20 `}
                onScroll={() => handleScrollHorizontal()}
            >
                <div className="okc-table__wrapper table-responsive">
                    <table style={style}>
                        <thead>{renderHeads()}</thead>
                        <tbody>{renderRows()}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});

export default Table;
