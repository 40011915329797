import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SideBar from './components/layout/SideBar';
import Header from './components/layout/Header';
import Loader from './components/common/Loader';
import Toast from './components/common/Toast';
import CommonModals from './components/layout/CommonModals';

const Layout = () => {
    return (
        <>
            <div className="d-flex">
                <Loader />
                <SideBar />
                <div className="okc-right">
                    <Header />
                    <div className="okc-container">
                        <Outlet />
                    </div>
                </div>
                <Toast />
                <ToastContainer />
                <CommonModals />
            </div>
        </>
    );
};

export default Layout;
