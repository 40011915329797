import UploadExcel from '../../components/layout/stores/UploadExcel';

const UploadExcelPage = () => {
    return (
        <div className="upload-registration">
            <UploadExcel />
        </div>
    );
};

export default UploadExcelPage;
