/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminRequest } from '../models/AdminRequest';
import type { AdminResponse } from '../models/AdminResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AdminControllerService {
    /**
     * @param adminId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateAdmin(
        adminId: number,
        requestBody: AdminRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/{adminId}',
            path: {
                'adminId': adminId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param keyword
     * @param userOrganization
     * @param page
     * @param limit
     * @param sortField
     * @param sortType
     * @returns AdminResponse OK
     * @throws ApiError
     */
    public static getAdmins(
        keyword?: string,
        userOrganization?: 'STORE' | 'HEADQUARTERS' | 'IT_HEADQUARTERS',
        page: number = 1,
        limit: number = 20,
        sortField?: string,
        sortType?: 'ASC' | 'DESC',
    ): CancelablePromise<AdminResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/',
            query: {
                'keyword': keyword,
                'userOrganization': userOrganization,
                'page': page,
                'limit': limit,
                'sortField': sortField,
                'sortType': sortType,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static registerAdmin(
        requestBody: AdminRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
