/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeRequestContinueCreateRequestItem } from './ChangeRequestContinueCreateRequestItem';
export type ChangeRequestContinueCreateRequest = {
    source?: ChangeRequestContinueCreateRequest.source;
    salesStartDate: string;
    salesEndDate: string;
    authorizerName?: string;
    items?: Array<ChangeRequestContinueCreateRequestItem>;
};
export namespace ChangeRequestContinueCreateRequest {
    export enum source {
        FLYER = 'FLYER',
        STORE = 'STORE',
    }
}

