import {
    IS_CONTINUE,
    FIFTY_DISCOUNT,
    TEN_DISCOUNT,
    THIRTY_DISCOUNT,
    COMMON,
    LIMIT_OVER,
    DELETE_CONFIRM,
    IS_DELETE_CONTINUE,
    PROHIBITED,
    EXCEL_VALIDATION,
} from './type';

export const setIsContinue = (data = false) => {
    return {
        type: IS_CONTINUE,
        data: data,
    };
};

export const setFiftyDiscount = (data = false) => {
    return {
        type: FIFTY_DISCOUNT,
        data: data,
    };
};

export const setThirtyDiscount = (data = false) => {
    return {
        type: THIRTY_DISCOUNT,
        data: data,
    };
};

export const setTenDiscount = (data = false) => {
    return {
        type: TEN_DISCOUNT,
        data: data,
    };
};

export const setLimitOver = (
    data = {
        isShow: false,
        items: [],
    },
) => {
    return {
        type: LIMIT_OVER,
        data: data,
    };
};

export const setProhibited = (
    data = {
        isShow: false,
        items: [],
    },
) => {
    return {
        type: PROHIBITED,
        data: data,
    };
};

export const setExcelValidation = (
    data = {
        isShow: false,
        items: [],
    },
) => {
    return {
        type: EXCEL_VALIDATION,
        data: data,
    };
};

export const setIsDeleteContinue = (data = false) => {
    return {
        type: IS_DELETE_CONTINUE,
        data: data,
    };
};

export const setDeleteConfirm = (data = false) => {
    return {
        type: DELETE_CONFIRM,
        data: data,
    };
};

export const setCommon = (
    data = {
        isShow: false,
        title: '',
        body: '',
    },
) => {
    return {
        type: COMMON,
        data: data,
    };
};
