import React from 'react';

const Label = (props) => {
    const { type, className, style } = props;

    if (type === 'column') {
        return (
            <label className={`${className}`} style={style}>
                {props.text}
            </label>
        );
    }

    return (
        <label className="form-group__label">
            <span className="form-group__label-text">{props.text}</span>
        </label>
    );
};

export default Label;
