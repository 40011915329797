import { configureStore } from '@reduxjs/toolkit';
import rootReducers from '../reducers/index';

const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
        }),
});

export default store;
