/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompetitorChainResponse } from '../models/CompetitorChainResponse';
import type { CompetitorStoreResponse } from '../models/CompetitorStoreResponse';
import type { DivisionResponse } from '../models/DivisionResponse';
import type { EnumResponse } from '../models/EnumResponse';
import type { StoreResponse } from '../models/StoreResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ConstantsControllerService {
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getUserOrganizations(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/user-organizations',
        });
    }
    /**
     * @returns StoreResponse OK
     * @throws ApiError
     */
    public static getStores(): CancelablePromise<Array<StoreResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/stores',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getSources(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/sources',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getSaleTypes(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/sale-types',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getRegistrationMethods(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/registration-methods',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getReceiverType(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/receiver-type',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getPackingCategories(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/packing-categories',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getItemCategories(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/item-categories',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getFrequencyRanks(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/frequency-ranks',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getExpandSalesFloors(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/expand-sales-floors',
        });
    }
    /**
     * @returns DivisionResponse OK
     * @throws ApiError
     */
    public static getDivisions(): CancelablePromise<Array<DivisionResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/divisions',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getDistances(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/distances',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getDepartments(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/departments',
        });
    }
    /**
     * @returns CompetitorStoreResponse OK
     * @throws ApiError
     */
    public static getCompetitorStoresByStoreCode(): CancelablePromise<Array<CompetitorStoreResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/competitor-stores',
        });
    }
    /**
     * @returns CompetitorChainResponse OK
     * @throws ApiError
     */
    public static getCompetitorChainsByStoreCode(): CancelablePromise<Array<CompetitorChainResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/competitor-chains',
        });
    }
    /**
     * @returns EnumResponse OK
     * @throws ApiError
     */
    public static getBusinessFormats(): CancelablePromise<Array<EnumResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/business-formats',
        });
    }
}
