import React, { useState } from 'react';
import Button from './Button';
import { useTranslation } from 'react-i18next';

const Card = (props) => {
    const { t } = useTranslation();
    const {
        type,
        children,
        handleClear,
        handleSubmit,
        handleNext,
        disabled,
        isCompletion,
        handleCompletion,
        className,
        toggle = handleSubmit || handleClear,
    } = props;
    const [state, setState] = useState({
        isShow: 'show',
    });

    const { isShow } = state;

    const handleShow = () => {
        setState((prevState) => ({
            ...prevState,
            isShow: isShow === 'show' ? '' : 'show',
        }));
    };

    if (type === 'none_footer') {
        return (
            <>
                <div className={`okc-card ${className} fade-in`}>
                    {/* <div className="okc-card"> */}
                    <div className="okc-card__body">
                        {/* <div className="row okc-card__row mb-0"> */}
                        {children}
                        {/* </div> */}
                    </div>
                    {/* </div> */}
                </div>
            </>
        );
    }

    if (type === 'modal_card') {
        return <div className="okc-card__body">{children}</div>;
    }

    return (
        <>
            <form
                method="POST"
                className="okc-accordion accordion fade-in"
                id="accordion"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit && handleSubmit();
                }}
            >
                <div className="okc-card">
                    <div className={`okc-card__body collapse ${isShow}`} id="collapse-body" data-parent="#accordion">
                        <div className="row okc-card__row mb-0">{children}</div>
                    </div>
                    {type === 'one_button_footer' ? (
                        <div className="okc-card__footer">
                            <Button
                                id="submit-select-store-btn"
                                className={`btn btn--primary btn-block ${disabled ? 'btn--disabled' : ''}`}
                                onClick={handleNext}
                                disabled={disabled}
                            >
                                <span className="btn-text">{t('button.next')}</span>
                            </Button>
                        </div>
                    ) : (
                        <div className="okc-card__footer justify-content-end">
                            {handleSubmit && (
                                <Button type="submit" className="btn btn--primary" onClick={() => handleSubmit()} id="btn-search-form">
                                    <span className="btn-text">{t('button.search')}</span>
                                </Button>
                            )}
                            {handleClear && (
                                <Button
                                    type="button"
                                    className="btn btn--secondary ml-2"
                                    onClick={() => handleClear()}
                                    id="btn-clear-search-form"
                                >
                                    <span className="btn-text">{t('button.clear')}</span>
                                </Button>
                            )}
                            {isCompletion && (
                                <Button type="button" className="btn btn--primary ml-2" onClick={() => handleCompletion()}>
                                    <span className="btn-text">{t('button.completion')}</span>
                                </Button>
                            )}
                            {toggle && (
                                <Button
                                    className="btn btn--icon btn--secondary ml-2"
                                    onClick={() => handleShow()}
                                    data-toggle="collapse"
                                    data-target="#collapse-body"
                                    aria-expanded="true"
                                >
                                    <i className="icon-next"></i>
                                    <span className="btn-text"></span>
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </form>
        </>
    );
};

export default Card;
