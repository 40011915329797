import React, { createRef } from 'react';
import '../../assets/css/common/button.css';

const Button = (props) => {
    const {
        text,
        className,
        onClick,
        disabled,
        children,
        fontSize,
        background,
        textColor,
        fontWeight,
        borderColor,
        endIcon,
        startIcon,
        width,
        height,
        type,
    } = props;
    const btn = createRef();

    const handleClickButton = (event) => {
        const { pageX, pageY, currentTarget } = event;

        const rect = currentTarget.getBoundingClientRect();
        const left = pageX - (rect.left + window.scrollX);
        const top = pageY - (rect.top + window.scrollY);
        if (!disabled) {
            const ripples = document.createElement('span');
            ripples.style.left = left + 'px';
            ripples.style.top = top + 'px';
            ripples.classList.add('components__button-ripple');
            btn?.current?.appendChild(ripples);

            const timeout = setTimeout(() => {
                clearTimeout(timeout);
                ripples.remove();
            }, 900);

            if (onClick) {
                onClick();
            }
        }
    };

    return (
        <button type={type} onClick={(event) => handleClickButton(event)} className={`${className}`}>
            {text != null ? text : children}
        </button>
    );
};

export default Button;
