const Checkbox = ({ column, id, label, onChange, value, disabled, checked }) => {
    return (
        <div className={`form-check custom-checkbox ${column ? column : ''}`}>
            <input
                className="form-check-input"
                onChange={onChange}
                type="checkbox"
                value={value}
                id={id}
                checked={checked}
                disabled={disabled}
            />
            <label className="form-check-label" htmlFor={id}>
                {label}
            </label>
        </div>
    );
};

export default Checkbox;
