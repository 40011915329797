import React, { useState } from 'react';

const Input = (props) => {
    const {
        name,
        id,
        disabled,
        type,
        className = '',
        classNameWrapper,
        onChange,
        onPress,
        value,
        rawValue,
        fontSize = '',
        placeholder,
        readOnly,
        maxLength,
        min,
        onKeyDown,
        onKeyUp,
        onBlur,
        onFocus,
        onClick,
        autoFocus,
        onSelectSuggest,
        onEndTyping,
        isSuggest,
        suggestions,
        autoComplete,
        style,
    } = props;

    const [state, setState] = useState({
        oldValue: value?.toString(),
        currentValue: rawValue ? rawValue : '',
        passwordShow: false,
        isSelectSuggest: false,
        isTyping: false,
        typingTimer: 1000,
        suggestionElement: undefined,
        suggestionInputElement: undefined,
    });

    const handleOnChange = (eventValue) => {
        let isValidValue = true;

        if (isValidValue && onChange) {
            setState((prevState) => ({
                ...prevState,
                oldValue: eventValue,
            }));

            onChange(eventValue);
        }
    };

    const handleOutFocus = (eventValue) => {
        let isValidValue = true;

        if (isValidValue && onBlur) {
            setState((prevState) => ({
                ...prevState,
                oldValue: eventValue,
            }));

            onBlur(eventValue);
        }
    };

    const handleOnTyping = () => {};

    if (type === 'select') {
        return (
            <input
                id={id}
                name={name}
                disabled={disabled}
                className={`form-control form-control--input ${className ? className : ''}`}
                style={{ ...style }}
                onBlur={(event) => handleOutFocus(event.target.value)}
                onInput={(event) => handleOnChange(event.target.value)}
                onClick={(event) => (onClick ? onClick(event) : {})}
                onFocus={(event) => (onFocus ? onFocus(event) : {})}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                readOnly={readOnly}
            />
        );
    }

    if (type === 'textarea') {
        return (
            <textarea
                id={id}
                name={name}
                disabled={disabled}
                value={value}
                className={`form-control ${fontSize} ${className}`}
                onInput={(event) => handleOnChange(event.target.value)}
                onBlur={(event) => handleOutFocus(event.target.value)}
                onClick={(event) => (onClick ? onClick(event) : {})}
                autoComplete="off"
                placeholder={placeholder}
                maxLength={maxLength}
            />
        );
    }

    return (
        <input
            type={type}
            id={id}
            name={name}
            disabled={disabled}
            className={`form-control form-control--input ${className}`}
            onBlur={(event) => handleOutFocus(event.target.value)}
            onInput={(event) => handleOnChange(event.target.value)}
            onKeyDown={(event) => {
                handleOnTyping();
                let checkIfNum = false;
                if (event.key !== undefined && type === 'number') {
                    checkIfNum = event.key === 'e' || event.key === '.' || event.key === '+' || event.key === '-';
                }
                return checkIfNum && event.preventDefault();
            }}
            onClick={(event) => (onClick ? onClick(event) : {})}
            onKeyPress={(event) => (onPress ? onPress(event) : {})}
            onFocus={(event) => (onFocus ? onFocus(event) : {})}
            autoComplete={autoComplete}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            readOnly={readOnly}
            style={{ ...style }}
        />
    );
};

export default Input;
