/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CombinationInfo = {
    id: number;
    storeDistance?: CombinationInfo.storeDistance;
    storeRank?: CombinationInfo.storeRank;
    deleteFlag?: boolean;
    notificationFlag?: boolean;
};
export namespace CombinationInfo {
    export enum storeDistance {
        WITHIN_10_MINUTES_ON_FOOT = 'WITHIN_10_MINUTES_ON_FOOT',
        BICYCLE_WITHIN_10_MINUTES = 'BICYCLE_WITHIN_10_MINUTES',
        BICYCLE_WITHIN_30_MINUTES = 'BICYCLE_WITHIN_30_MINUTES',
        BICYCLE_30_MINUTES_OR_MORE = 'BICYCLE_30_MINUTES_OR_MORE',
        OTHERS = 'OTHERS',
    }
    export enum storeRank {
        TWICE_EVERY_WEEK = 'TWICE_EVERY_WEEK',
        ONCE_EVERY_WEEK = 'ONCE_EVERY_WEEK',
        ONCE_EVERY_TWO_WEEKS = 'ONCE_EVERY_TWO_WEEKS',
        ONCE_EVERY_MONTH = 'ONCE_EVERY_MONTH',
    }
}

