import {
    CHANGE_REQUEST_TYPE,
    REQUESTER_NAME,
    SELECTED_COMPETITOR_STORE_CODE,
    SELECTED_DEPARTMENTS,
    SELECTED_REGISTRATION_METHOD,
    SELECTED_SOURCE,
    ORGANIZATION,
    AUTHORITY,
} from './type';
import { AdminRequest } from '../../services';

export const setOrganization = (data = AdminRequest.organization) => {
    return {
        type: ORGANIZATION,
        data: data,
    };
};
export const setAuthority = (data = AdminRequest.authority) => {
    return {
        type: AUTHORITY,
        data: data,
    };
};

export const setChangeRequestType = (data = '') => {
    return {
        type: CHANGE_REQUEST_TYPE,
        data: data,
    };
};

export const setCompetitorStore = (data = '') => {
    return {
        type: SELECTED_COMPETITOR_STORE_CODE,
        data: data,
    };
};

export const setRequesterName = (data = '') => {
    return {
        type: REQUESTER_NAME,
        data: data,
    };
};

export const setSource = (data = '') => {
    return {
        type: SELECTED_SOURCE,
        data: data,
    };
};

export const setRegistrationMethod = (data = '') => {
    return {
        type: SELECTED_REGISTRATION_METHOD,
        data: data,
    };
};

export const setDepartments = (array = []) => {
    return {
        type: SELECTED_DEPARTMENTS,
        data: array,
    };
};
