/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SurveyHistoryResponse } from '../models/SurveyHistoryResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SurveyHistoryControllerService {
    /**
     * @param page
     * @param limit
     * @returns SurveyHistoryResponse OK
     * @throws ApiError
     */
    public static getSurveyHistory(
        page: number = 1,
        limit: number = 20,
    ): CancelablePromise<SurveyHistoryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/survey-history',
            query: {
                'page': page,
                'limit': limit,
            },
        });
    }
}
