import PriceSearch from '../../components/layout/stores/PriceSearch';
import '../../assets/css/pages/stores/price-search.css';

const PriceRegistrationPage = () => {
    return (
        <div className="price-registration">
            <PriceSearch />
        </div>
    );
};

export default PriceRegistrationPage;
