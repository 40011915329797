import '../../assets/css/common/modal.css';
import { Modal } from 'react-bootstrap';

const ImageModal = ({ show, handleClose, imageUrl }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <img src={imageUrl} alt="Modal Content" style={{ width: '100%' }} />
            </Modal.Body>
        </Modal>
    );
};

export default ImageModal;
