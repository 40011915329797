/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FormulaControllerService {
    /**
     * @param discountedRequestPrice
     * @param discountedCategory
     * @returns number OK
     * @throws ApiError
     */
    public static getSellingPriceFromDiscountedSellingPrice(
        discountedRequestPrice: number,
        discountedCategory: string,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/selling-price',
            query: {
                'discountedRequestPrice': discountedRequestPrice,
                'discountedCategory': discountedCategory,
            },
        });
    }
    /**
     * @param requestPrice
     * @param discountedCategory
     * @returns number OK
     * @throws ApiError
     */
    public static getDiscountedSellingPriceFromSellingPrice(
        requestPrice: number,
        discountedCategory: string,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/discounted-selling-price',
            query: {
                'requestPrice': requestPrice,
                'discountedCategory': discountedCategory,
            },
        });
    }
    /**
     * @param requestSellingPrice
     * @param sellingPrice
     * @param percentage
     * @returns boolean OK
     * @throws ApiError
     */
    public static isDeviated(
        requestSellingPrice: number,
        sellingPrice: number,
        percentage: number,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/deviated',
            query: {
                'requestSellingPrice': requestSellingPrice,
                'sellingPrice': sellingPrice,
                'percentage': percentage,
            },
        });
    }
}
