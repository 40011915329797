import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import '../../assets/css/common/dropzone.css';

const Dropzone = (props) => {
    const { file, onChange, error } = props;

    const { t } = useTranslation();
    const [state, setState] = useState({
        error: false,
    });
    // const { error } = state;

    const onDrop = useCallback((acceptedFiles) => {
        onChange(acceptedFiles[0]);
    }, []);

    const sizeFile = (file) => {
        var selectedSize = 0;
        const filesizeBase = 1000;
        const dictFileSizeUnits = {
            tb: 'TB',
            gb: 'GB',
            mb: 'MB',
            kb: 'KB',
            b: 'b',
        };
        var selectedUnit = 'b';

        if (file.size > 0) {
            var units = ['tb', 'gb', 'mb', 'kb', 'b'];

            for (var i = 0; i < units.length; i++) {
                var unit = units[i];
                var cutoff = Math.pow(filesizeBase, 4 - i) / 10;

                if (file.size >= cutoff) {
                    selectedSize = file.size / Math.pow(filesizeBase, 4 - i);
                    selectedUnit = unit;
                    break;
                }
            }

            selectedSize = Math.round(10 * selectedSize) / 10;
        }

        return ''.concat(selectedSize, ' ').concat(dictFileSizeUnits[selectedUnit]);
    };

    const typeValidator = (file) => {
        switch (file.type) {
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                setState((prevState) => ({
                    ...prevState,
                    error: false,
                }));
                break;
            default:
                setState((prevState) => ({
                    ...prevState,
                    error: true,
                }));
                break;
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        validator: typeValidator,
    });

    return (
        <div className="dropzone-container" {...getRootProps()} style={{ border: `2px dashed ${error ? '#ff0000' : '#d66f00'}` }}>
            <input {...getInputProps()} />
            {file ? (
                <div className="file-preview">
                    <div className="file-preview-content">
                        <span className="file-size">{sizeFile(file)}</span>
                        <span className="file-name">{file.name}</span>
                    </div>
                </div>
            ) : (
                <>
                    <button className="upload-button">
                        {t('upload_registration.box.select_file.text')} <i className="icon-upload ml-2"></i>
                    </button>
                    <span>{t('upload_registration.box.drag_file')}</span>
                </>
            )}
            {/* {error && <p className="okc-error mt-2 mb-0">{t('common.file.error')}</p>} */}
        </div>
    );
};

export default Dropzone;
