import Input from '../common/Input';
import '../../assets/css/common/input.css';
import Image from '../common/Image';
import logo from '../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import Label from '../common/Label';
import Button from '../common/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import env from '../../configs/env';
import { resetStorage } from '../../redux/reducers/storage';
import { useDispatch } from 'react-redux';
import { setAuthority, setChangeRequestType, setOrganization } from '../../redux/actions/storage';
import { AdminRequest, PriceSearchRequest } from '../../services';
import apiErrorHandler from '../../api/apiErrorHandler';

const Login = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        username: '',
        password: '',
    });

    const { username, password } = state;

    const handleChangeInput = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmitLogin = (response) => {
        const { authority, organization } = response;
        // デフォルトとして競合対抗をセット
        dispatch(setChangeRequestType(PriceSearchRequest.changeRequestType.COMPETITOR));
        // レスポンスをセット
        dispatch(setAuthority(authority));
        dispatch(setOrganization(organization));

        switch (organization) {
            case AdminRequest.organization.STORE:
                history('/select-menu');
                break;
            case AdminRequest.organization.HEADQUARTERS:
                history('/select-store');
                break;
            case AdminRequest.organization.IT_HEADQUARTERS:
                history('/users');
                break;
            default:
        }
    };

    const handleSubmit = () => {
        axios
            .post(`${env.BACKEND_BASE_URL}/login`, state, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                withCredentials: true,
            })
            .then((response) => {
                handleSubmitLogin(response.data);
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            });
    };

    useEffect(() => {
        resetStorage();
    }, []);

    return (
        <div className="row no-gutters">
            <div className="col-12 col-md-6 col-lg-4 m-auto">
                <form
                    method="POST"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <div className="auth">
                        <div className="auth__inner">
                            <div className="auth__logo fade-in">
                                <Image src={logo} />
                            </div>
                            <div className="auth__form c-slide-top">
                                <div className="form-group">
                                    <Label text={t('login.title.store_number')} />
                                    <Input
                                        name="username"
                                        value={username}
                                        type="text"
                                        placeholder={t('login.title.store_number')}
                                        onChange={(value) => handleChangeInput('username', value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <Label text={t('login.title.password')} />
                                    <Input
                                        name="password"
                                        value={password}
                                        type="password"
                                        placeholder={t('login.title.password')}
                                        onChange={(value) => handleChangeInput('password', value)}
                                    />
                                </div>
                                <div className="auth__action">
                                    <Button type="submit" className="btn btn--primary btn--lg" text={t('header.login')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
