/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BadgeResponse } from '../models/BadgeResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MenuControllerService {
    /**
     * @param changeRequestType
     * @returns BadgeResponse OK
     * @throws ApiError
     */
    public static getBadges(
        changeRequestType: 'COMPETITOR' | 'FRESHNESS' | 'INSTRUCTION',
    ): CancelablePromise<BadgeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/badges',
            query: {
                'changeRequestType': changeRequestType,
            },
        });
    }
}
