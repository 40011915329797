/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompetitorChainResponse } from '../models/CompetitorChainResponse';
import type { CompetitorCombinationCreateRequest } from '../models/CompetitorCombinationCreateRequest';
import type { CompetitorSearchResponse } from '../models/CompetitorSearchResponse';
import type { CompetitorStoreCreateRequest } from '../models/CompetitorStoreCreateRequest';
import type { CompetitorStoreResponse } from '../models/CompetitorStoreResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CompetitorMasterControllerService {
    /**
     * @param competitorStoreMasterId
     * @param storeRank
     * @returns any OK
     * @throws ApiError
     */
    public static updateStoreRank(
        competitorStoreMasterId: number,
        storeRank: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/competitor/store-rank/{competitorStoreMasterId}',
            path: {
                'competitorStoreMasterId': competitorStoreMasterId,
            },
            query: {
                'storeRank': storeRank,
            },
        });
    }
    /**
     * @returns CompetitorStoreResponse OK
     * @throws ApiError
     */
    public static getCompetitorStores(): CancelablePromise<Array<CompetitorStoreResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitor/stores',
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createCompetitorStore(
        requestBody: CompetitorStoreCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/competitor/stores',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param registrationDate
     * @param storeCode
     * @param competitorChainCode
     * @param competitorStoreCode
     * @param page
     * @param limit
     * @param sortField
     * @param sortType
     * @returns CompetitorSearchResponse OK
     * @throws ApiError
     */
    public static searchCompetitorCombinations(
        registrationDate?: string,
        storeCode?: string,
        competitorChainCode?: string,
        competitorStoreCode?: string,
        page: number = 1,
        limit: number = 20,
        sortField?: string,
        sortType?: 'ASC' | 'DESC',
    ): CancelablePromise<CompetitorSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitor/combinations',
            query: {
                'registrationDate': registrationDate,
                'storeCode': storeCode,
                'competitorChainCode': competitorChainCode,
                'competitorStoreCode': competitorStoreCode,
                'page': page,
                'limit': limit,
                'sortField': sortField,
                'sortType': sortType,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createCompetitorMaster(
        requestBody: CompetitorCombinationCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/competitor/combinations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CompetitorChainResponse OK
     * @throws ApiError
     */
    public static getCompetitorChains(): CancelablePromise<Array<CompetitorChainResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitor/chains',
        });
    }
}
