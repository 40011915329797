import React, { useEffect } from 'react';
import { Bounce, toast } from 'react-toastify';
import '../../assets/css/common/toast.css';
import error from '../../assets/images/error.png';
import warning from '../../assets/images/warning.png';
import success from '../../assets/images/success.png';
import { useSelector } from 'react-redux';

const Toast = () => {
    const { toasts } = useSelector((states) => states);

    useEffect(() => {
        handleShowToast(toasts);
    }, [toasts]);

    const handleShowToast = (toasts) => {
        if (toasts.type === 'set_error') {
            return toast.error(toasts.message, {
                autoClose: 5000,
                transition: Bounce,
                pauseOnHover: false,
                theme: 'colored',
                closeOnClick: true,
                icon: <img src={error} alt="custom icon" style={{ width: '24px', height: '24px' }} />,
                progress: 0,
                pauseOnFocusLoss: false,
                closeButton: false,
                hideProgressBar: true,
                className: 'toast_custom',
                bodyClassName: 'body_toast',
            });
        }

        if (toasts.type === 'set_warning') {
            toast.warning(toasts.message, {
                autoClose: 5000,
                transition: Bounce,
                pauseOnHover: false,
                theme: 'colored',
                closeOnClick: true,
                icon: <img src={warning} alt="custom icon" style={{ width: '24px', height: '24px' }} />,
                progress: 0,
                pauseOnFocusLoss: false,
                closeButton: false,
                hideProgressBar: true,
                className: 'toast_custom',
                bodyClassName: 'body_toast',
            });
        }

        if (toasts.type === 'set_success') {
            toast.success(toasts.message, {
                autoClose: 5000,
                transition: Bounce,
                pauseOnHover: false,
                theme: 'colored',
                closeOnClick: true,
                icon: <img src={success} alt="custom icon" style={{ width: '24px', height: '24px' }} />,
                progress: 0,
                pauseOnFocusLoss: false,
                closeButton: false,
                hideProgressBar: true,
                className: 'toast_custom',
                bodyClassName: 'body_toast',
            });
        }
    };
};

export default Toast;
