import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/common/Card';
import Label from '../../components/common/Label';
import Input from '../../components/common/Input';
import Pagination from '../../components/common/Pagination';
import '../../assets/css/pages/ITheadquarters/users.css';
import { AdminControllerService } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserOrganizations, setLoader } from '../../redux/actions/api';
import EditTable from '../../components/tables/EditTable';
import { getAdminTable } from '../../components/tables/table';
import apiErrorHandler from '../../api/apiErrorHandler';

const DEFAULT_STATE = {
    page: 1,
    limit: 20,
    apiResponse: null,
    keyword: '',
    userOrganization: '',
};

const UserPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useState(DEFAULT_STATE);

    const { page, limit, apiResponse, keyword, userOrganization } = state;

    const sort = useSelector((state) => state.sort);

    const constants = useSelector((state) => state.constants);
    const { userOrganizations } = constants;

    const table = getAdminTable(t);

    useEffect(() => {
        dispatch(fetchUserOrganizations());
    }, [dispatch]);

    useEffect(() => {
        getAdmins();
    }, [dispatch, page, sort]);

    const getAdmins = () => {
        dispatch(setLoader(true));
        AdminControllerService.getAdmins(
            keyword ? keyword : null,
            userOrganization ? userOrganization : null,
            page,
            limit,
            sort.sortField,
            sort.sortType,
        )
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    apiResponse: response,
                }));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmit = () => {
        handleChangeState('page', 1);
        getAdmins();
    };

    const handleClearForm = () => {
        setState(DEFAULT_STATE);
        getAdmins();
    };

    return (
        <div className="price-request-competitive">
            <Card handleSubmit={handleSubmit} handleClear={handleClearForm} toggle={false}>
                <Label type="column" text={t('user.label.choose')} className="col-lg-2 mb-2 mb-lg-4" />
                <div className="col-lg-4 pr-lg-3 mb-4">
                    <Input
                        className="form-control form-control--input form-input--width"
                        id="input-4"
                        type="text"
                        autocomplete="off"
                        maxLength="60"
                        name="keyword"
                        onChange={(value) => handleChangeState('keyword', value)}
                        value={keyword}
                    />
                </div>
                <div className="col-lg-6"></div>
                <Label type="column" text={t('user.label.classification')} className="col-lg-2 mb-2 mb-lg-4 mt-lg-2 align-self-start" />
                <div className="col-lg-10 mb-4 competitive-store__radios">
                    <div className="row m-0 competitive-store__row js-okc-validate">
                        {userOrganizations?.map((option, index) => (
                            <div key={index} className={`form-check custom-radio col-xl-3 col-lg-4 col-md-5`}>
                                <input
                                    className={`${option.label ? 'bases__margin--right4' : ''} form-check-input`}
                                    type="radio"
                                    value={option.value}
                                    name={option.id}
                                    id={option.id}
                                    checked={userOrganization === option.value}
                                    onChange={() => handleChangeState('userOrganization', option.value)}
                                />
                                <label className="form-check-label" htmlFor={option.id}>
                                    {option.label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </Card>
            <Pagination page={page} pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
            <EditTable isHeightTr heads={table.heads} body={table.body} dataTable={apiResponse?.data} />
        </div>
    );
};

export default UserPage;
