import { Modal as ModalBootstrap } from 'react-bootstrap';
import '../../assets/css/common/modal.css';

const Modal = (props) => {
    const { children, title, onClose, isShow, size, isShowIconClose, className, style, btnList = [] } = props;

    const handleModalHide = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <ModalBootstrap
            size={size ? size : 'xl'}
            centered={true}
            show={isShow}
            onHide={() => handleModalHide()}
            keyboard={false}
            className={`okc-modal ${className}`}
            style={style}
        >
            <div className="okc-modal__content modal-content">
                <div className="okc-modal__header modal-header">
                    <h5 className="modal-title">{title}</h5>
                    {isShowIconClose && (
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={() => handleModalHide()}>
                            <i className="icon-close" aria-hidden="true"></i>
                        </button>
                    )}
                </div>
            </div>
            <div className="okc-modal__body modal-body">
                <div>{children ?? <></>}</div>
            </div>
            <div className="okc-modal__footer modal-footer">
                <div className="okc-modal__button-group">
                    {btnList.map((data, index) => (
                        <button
                            key={index}
                            className={`btn ${data.className}`}
                            data-dismiss="modal"
                            disabled={data.disabled}
                            onClick={() => data.onClick()}
                        >
                            <span className="btn-text">{data.name}</span>
                        </button>
                    ))}
                </div>
            </div>
        </ModalBootstrap>
    );
};

export default Modal;
