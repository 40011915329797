export class Environment {
    ENV = process.env.REACT_APP_ENV;
    BACKEND_BASE_URL = '';
    FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
    SYSTEM_NAME = 'storepricechange';
}

const env = new Environment();
switch (process.env.REACT_APP_ENV) {
    case 'mock':
        env.BACKEND_BASE_URL = 'http://127.0.0.1:8000';
        break;
    case 'local':
        env.BACKEND_BASE_URL = 'http://localhost:8080';
        break;
    default:
        env.BACKEND_BASE_URL = `https://api.${env.SYSTEM_NAME}-${env.ENV}.okc-sys.com`;
}

export default env;
