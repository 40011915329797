/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExceptionCategorySearchRequest } from '../models/ExceptionCategorySearchRequest';
import type { ExceptionCategorySearchResponse } from '../models/ExceptionCategorySearchResponse';
import type { ExceptionCategoryUpsertRequest } from '../models/ExceptionCategoryUpsertRequest';
import type { ExceptionItemSearchRequest } from '../models/ExceptionItemSearchRequest';
import type { ExceptionItemSearchResponse } from '../models/ExceptionItemSearchResponse';
import type { ExceptionItemUpsertRequest } from '../models/ExceptionItemUpsertRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExceptionControllerService {
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static upsertExceptionItems(
        requestBody: ExceptionItemUpsertRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/exception/item',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ExceptionItemSearchResponse OK
     * @throws ApiError
     */
    public static getExceptionItems(
        requestBody: ExceptionItemSearchRequest,
    ): CancelablePromise<ExceptionItemSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/exception/item',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static upsertExceptionCategory(
        requestBody: ExceptionCategoryUpsertRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/exception/category',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ExceptionCategorySearchResponse OK
     * @throws ApiError
     */
    public static getExceptionCategories(
        requestBody: ExceptionCategorySearchRequest,
    ): CancelablePromise<ExceptionCategorySearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/exception/category',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
