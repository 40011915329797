import { FormulaControllerService } from '../../services';

// Deprecated
export const checkSellingPrice = function checkSellingPrice(competitorSellingPrice, lowestSellingPrice) {
    return competitorSellingPrice < lowestSellingPrice * 0.9;
};

// Deprecated
export const istThirtyPercentSellingPrice = function (competitorSellingPrice, lowestSellingPrice) {
    return competitorSellingPrice < lowestSellingPrice * 0.7;
};

// Deprecated
export const isFiftyPercentSellingPrice = function (competitorSellingPrice, lowestSellingPrice) {
    return competitorSellingPrice < Math.trunc(lowestSellingPrice * 0.5);
};

// 乖離判定（API経由）
export const isDeviated = async (requestSellingPrice, comparePrice, percentage) => {
    return FormulaControllerService.isDeviated(requestSellingPrice, comparePrice, percentage);
};

// 本体価格を取得（API経由）
export const getSellingPrice = async (sellingPrice, discountedCategory) => {
    return FormulaControllerService.getSellingPriceFromDiscountedSellingPrice(sellingPrice, discountedCategory);
};

// 会員価格を取得（API経由）
export const getDiscountedSellingPrice = async (sellingPrice, discountedCategory) => {
    return FormulaControllerService.getDiscountedSellingPriceFromSellingPrice(sellingPrice, discountedCategory);
};

// 割引価格取得
export const getPercentagePrice = (price, percentage) => {
    return Math.trunc(price * (1 - parseInt(percentage) / 100));
};

// 税抜価格取得
export const getTaxExcludedPrice = (sellingPrice, taxRate) => {
    // 税抜価格 = 税込価格 - 切り捨て（税込価格 / ( 100 + 税率 ) * 税率）
    // 10000 = 11000 - 11000 / (1 + 0.1) * 0.1
    return sellingPrice - Math.trunc((sellingPrice / (1 + taxRate / 100)) * (taxRate / 100));
};

// 税込価格取得
export const getTaxIncludedPrice = (sellingPrice, taxRate) => {
    return Math.trunc(sellingPrice * (1 + taxRate / 100));
};

// ユニット単価取得
export const getUnitPrice = (sellingPrice, capacity2, unitQuantity) => {
    sellingPrice = parseInt(sellingPrice);
    capacity2 = parseFloat(capacity2);
    unitQuantity = parseInt(unitQuantity);
    if (isNaN(sellingPrice) || isNaN(capacity2) || isNaN(unitQuantity) || capacity2 === 0) {
        return 0;
    }
    return Math.ceil((sellingPrice * unitQuantity) / capacity2);
};
