import { setToastsError } from '../redux/actions/common';

const apiErrorHandler = (dispatch, error) => {
    switch (error.status) {
        case 401:
            window.location.href = '/login';
            break;
        default:
            dispatch(setToastsError(error.body.message));
            break;
    }
};

export default apiErrorHandler;
