const Choice = ({
    className = '',
    type = 'radio',
    checked = [],
    viewMode = 'horizontal',
    id = 'choice',
    data,
    onChange,
    column,
    name,
    labelCheck,
    disabled,
    isChecked,
    isAlwayDisable,
}) => {
    const onChangeCheckboxValues = (value, event) => {
        const valueChecked = checked ? [...checked] : [];

        const existedIndex = valueChecked.indexOf(value);
        if (existedIndex >= 0) {
            valueChecked.splice(existedIndex, 1);
        } else {
            valueChecked.push(value);
        }

        if (onChange) {
            onChange(valueChecked, event);
        }
    };

    if (type === 'checkbox') {
        return column === null ? (
            <div className={`components__choice ${viewMode === 'horizontal' ? 'd-flex align-items-center flex-wrap' : ''} ${className}`}>
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`${
                            item.label ? (viewMode === 'horizontal' ? 'bases__margin--right16' : 'bases__margin--vertical16') : ''
                        } d-flex align-items-center form-check custom-checkbox`}
                    >
                        <input
                            className="form-check-input"
                            onChange={(event) => onChangeCheckboxValues(item.value, event.target.value)}
                            type="checkbox"
                            value={item.value}
                            id={item.id}
                            checked={checked && checked?.includes(item.value ?? '')}
                        />
                        <label className="form-check-label" htmlFor={`${id}_${item.id}`}>
                            {item.label}
                        </label>
                    </div>
                ))}
            </div>
        ) : (
            <>
                {data.map((item, index) => (
                    <div key={index} className={`form-check custom-checkbox ${column}`}>
                        <input
                            className="form-check-input"
                            onChange={(event) => onChangeCheckboxValues(item.value, event.target.value)}
                            type="checkbox"
                            value={item.value}
                            id={item.id}
                            checked={isChecked || checked?.includes(item.value ?? '')}
                            name={name}
                            disabled={disabled}
                        />
                        <label className="form-check-label" htmlFor={item.id}>
                            {item.label}
                        </label>
                    </div>
                ))}
            </>
        );
    }

    return (
        <>
            {data.map((item, index) => (
                <div key={index} className={`form-check custom-radio ${column} ${item.disabled ? 'radio-disabled' : ''}`}>
                    <input
                        className={`${item.label ? 'bases__margin--right4' : ''} form-check-input`}
                        type="radio"
                        value={item.value}
                        name={id}
                        id={`${id}_${item.id}`}
                        checked={checked && checked?.includes(item.value)}
                        onChange={(event) => (onChange ? onChange([event.target.value]) : {})}
                    />
                    <label className="form-check-label" htmlFor={`${id}_${item.id}`} disabled={item.disabled}>
                        {item.label}
                    </label>
                </div>
            ))}
        </>
    );
};

export default Choice;
