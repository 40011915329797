import React from 'react';
import LazyImg from 'react-cool-img';

const Image = (props) => {
    const handleOnChange = (eventValue) => {};

    const handleOutFocus = (eventValue) => {};

    const handleOnTyping = () => {};

    const handleImageLoaded = () => {};

    const onClick = () => {};
    return (
        <>
            <LazyImg
                onClick={() => (onClick ? onClick() : {})}
                onLoad={() => handleImageLoaded()}
                debounce={0}
                src={props.src}
                width={props.width}
                height={props.height}
                draggable={false}
            />
            <noscript>
                <img className={props.className} src={props.src} alt="OK" />
            </noscript>
        </>
    );
};

export default Image;
