import { useEffect, useState, useRef } from 'react';
import Table from '../../components/common/Table';
import Tabs from '../../components/common/Tabs';
import '../../assets/css/pages/stores/change-request.css';
import Button from '../../components/common/Button';
import Card from './../../components/common/Card';
import SelectSearch from '../../components/common/SelectSearch';
import Label from './../../components/common/Label';
import { useTranslation } from 'react-i18next';

const DemoTablePage = () => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        checkboxChecked: [],
        radioChecked: '1',
        isShowCard: false,
        option: '',
        stateTableRequest: [],
        dataTableRequest: [],
        stateTableConfirm: [],
        dataTableConfirm: [],
        dataTableSelectRow: [],
    });

    const { isShowCard, option, stateTableRequest, dataTableRequest, stateTableConfirm, dataTableConfirm, dataTableSelectRow } = state;
    const [dataTableSelect, setStateDataTableSelect] = useState([]);

    useEffect(() => {
        const dataRender = handleTableDatas(data);
        const dataRender1 = handleTableDatas1(data);
        setState((prevState) => ({
            ...prevState,
            stateTableRequest: table,
            dataTableRequest: dataRender,
            stateTableConfirm: table1,
            dataTableConfirm: dataRender1,
        }));
    }, []);

    const tableRef = useRef();

    const handleSort = (tableSort, field, type) => {
        if (tableSort === 1) {
            table.heads.forEach((item) => {
                if (item?.field === field) {
                    item.type = type;
                } else {
                    if (item.type === 'DESC') {
                        item.type = 'ASC';
                    }
                }
            });
            setState((prevState) => ({
                ...prevState,
                stateTableRequest: table,
            }));
        } else {
            table1.heads.forEach((item) => {
                if (item?.field === field) {
                    item.type = type;
                } else {
                    if (item.type === 'DESC') {
                        item.type = 'ASC';
                    }
                }
            });
            setState((prevState) => ({
                ...prevState,
                stateTableConfirm: table1,
            }));
        }
    };

    const handleSelectRow = (index, recordId) => {
        if (dataTableSelectRow.includes(dataTableConfirm[index])) {
            dataTableSelectRow.splice(dataTableSelectRow.indexOf(dataTableConfirm[index]), 1);
        } else {
            dataTableSelectRow.push(dataTableConfirm[index]);
        }

        if (dataTableSelectRow.length === 0) {
            setStateDataTableSelect([]);
        } else {
            setStateDataTableSelect([dataTableSelectRow]);
        }
    };

    const handleDeleteRow = (indexRow) => {
        dataTableSelectRow.splice(indexRow, 1);
        if (dataTableSelectRow.length === 0) {
            setStateDataTableSelect([]);
        } else {
            setStateDataTableSelect([dataTableSelectRow]);
        }
    };

    const data = [
        {
            id: 1,
            field_1: '4902046666065',
            field_2: 'カネタ　味噌汁の具　ほうれん草と高野豆腐　業務用１４０ｇ',
            field_3: '食品館あおば　野毛店',
            field_4: '550',
            field_5: '545',
            field_6: 'ー',
            field_7: 'ー',
            field_8: '550',
            field_9: '545',
            field_10: '2024/07/17',
            field_11: '2024/07/17',
            field_12: 'なし',
            field_13: 'ー',
            fixedPrice: '1',
            unitCode: '2',
            selectedCapacity2: '3',
        },
        {
            id: 2,
            field_1: '4902046666072',
            field_2: 'カネタ　味噌汁の具　布のりと焼麩　業務用１４０ｇ',
            field_3: '食品館あおば　野毛店',
            field_4: '550',
            field_5: '545',
            field_6: 'ー',
            field_7: 'ー',
            field_8: '550',
            field_9: '545',
            field_10: '2024/07/17',
            field_11: '2024/07/17',
            field_12: 'なし',
            field_13: 'ー',
            fixedPrice: '1',
            unitCode: '2',
            selectedCapacity2: '3',
        },
        {
            id: 3,
            field_1: '4902046569274',
            field_2: 'Testttttttt',
            field_3: 'Competitor Store Name',
            field_4: '400',
            field_5: '380',
            field_6: 'ー',
            field_7: 'ー',
            field_8: '550',
            field_9: '545',
            field_10: '2024/07/17',
            field_11: '2024/07/17',
            field_12: 'なし',
            field_13: 'ー',
            fixedPrice: '1',
            unitCode: '2',
            selectedCapacity2: '4',
        },
    ];

    const handleTableDatas = (dataRender) => {
        // data is from API, this is just a mock
        return dataRender.map((data) => {
            return {
                ...data,
                textarea: [
                    {
                        type: 'textarea',
                        maxLength: '30',
                    },
                ],
            };
        });
    };

    const table = {
        heads: [
            {
                title: 'JAN',
                field: 'jan',
                isSort: true,
                type: 'ASC',
                className: 'v-base_minWidth--95 v-base_width--6',
            },
            {
                title: '商品名',
                field: 'orig_jan',
                isSort: true,
                type: 'DESC',
                className: 'v-base_minWidth--115 v-width-auto cell-border',
            },
            {
                title: '競合店名',
                isSort: true,
                field: 'item_name',
                type: 'ASC',
                className: 'v-base_minWidth--65 v-base_width--10',
            },
            {
                title: '自店現売価 \n （本体価格）',
                isSort: true,
                field: 'lowest_selling_price',
                type: 'ASC',
                className: 'text-center v-base_minWidth--85 v-base_width--4',
            },
            {
                title: '自店現売価 \n （会員価格）',
                isSort: true,
                field: 'discounted_lowest_selling_price',
                type: 'ASC',
                className: 'text-center v-base_minWidth--85 v-base_width--4',
            },
            {
                title: '売価種別',
                field: 'lowest_price_type_id',
                isSort: true,
                type: 'ASC',
                className: 'v-base_minWidth--40 v-base_width--4',
            },
            {
                title: '現売価特売 \n 終了日',
                field: 'effective_end_date',
                isSort: true,
                type: 'ASC',
                className: 'v-base_minWidth--75 v-base_width--5',
            },
            {
                title: '競合店売価\n（本体価格）',
                className: 'text-center v-base_minWidth--85 v-base_width--4',
                isSort: true,
                field: 'discounted_competitor_selling_price',
                type: 'ASC',
            },
            {
                title: '競合店売価\n（会員価格）',
                isSort: true,
                field: 'competitor_selling_price',
                type: 'ASC',
                className: 'text-center v-base_minWidth--85 v-base_width--4',
            },
            {
                title: '開始日',
                className: 'text-center v-base_minWidth--65 v-base_width--6',
                isSort: true,
                field: 'sales_start_date',
                type: 'ASC',
            },
            {
                title: '終了日',
                isSort: true,
                field: 'sales_end_date',
                className: 'text-center v-base_minWidth--65 v-base_width--6',
                type: 'ASC',
            },
            {
                title: '競合対抗補填',
                isSort: true,
                className: 'text-center v-base_minWidth--85 v-base_width--7',
                type: 'ASC',
            },
            {
                title: '仕入止め',
                isSort: true,
                field: 'stop_purchase_flag',
                className: 'text-center v-base_minWidth--65 v-base_width--6',
                type: 'textarea',
            },
            {
                title: '選択',
                className: 'v-base_minWidth--53 v-base_width--2  freeze-column freeze-right-0 cell-border-left',
            },
        ],
        body: {
            columns: [
                {
                    field: 'field_1',
                },
                {
                    field: 'field_2',
                    className: 'cell-border',
                },
                {
                    field: 'field_3',
                },
                {
                    field: 'field_4',
                },
                {
                    field: 'field_5',
                },
                {
                    field: 'field_6',
                },
                {
                    field: 'field_7',
                },
                {
                    field: 'field_8',
                },
                {
                    field: 'field_9',
                },
                {
                    field: 'field_10',
                },
                {
                    field: 'field_11',
                },
                {
                    field: 'field_12',
                },
                {
                    field: 'textarea',
                    isInput: true,
                    isCheckbox: false,
                },
                {
                    name: 'requestFlg',
                    className: 'freeze-column freeze-right-0 cell-border-left',
                    field: 'field_13',
                },
            ],
            // rows: handleTableDatas(),
        },
    };

    const handleTableDatas1 = (dataRender) => {
        return dataRender.map((data) => {
            return {
                ...data,
                checkbox_checked: [
                    {
                        type: 'checkbox',
                        data: [
                            {
                                id: `approve_${data.id}`,
                                value: data.id,
                            },
                        ],
                    },
                ],
                checkbox_remand: [
                    {
                        type: 'checkbox',
                        data: [
                            {
                                id: `remand_${data.id}`,
                                value: data.id,
                            },
                        ],
                    },
                ],
                field_12: data.field_12,
            };
        });
    };

    const table1 = {
        heads: [
            {
                title: 'JAN',
                field: 'jan',
                isSort: true,
                type: 'ASC',
            },
            {
                title: '商品名',
                field: 'orig_jan',
                isSort: true,
                type: 'DESC',
            },
            {
                title: '競合店名',
                isSort: true,
                field: 'item_name',
                type: 'ASC',
            },
            {
                title: '自店現売価（本体価格）',
                className: 'text-center',
                isSort: true,
                field: 'lowest_selling_price',
                type: 'ASC',
            },
            {
                title: '自店現売価（会員価格）',
                className: 'text-center',
                isSort: true,
                field: 'discounted_lowest_selling_price',
                type: 'ASC',
            },
            {
                title: '売価種別',
                field: 'lowest_price_type_id',
                isSort: true,
                type: 'ASC',
            },
            {
                title: '現売価特売 \n 終了日',
                field: 'effective_end_date',
                isSort: true,
                type: 'ASC',
            },
            {
                title: '競合店売価\n（本体価格））',
                className: 'text-center',
                isSort: true,
                field: 'discounted_competitor_selling_price',
                type: 'ASC',
            },
            {
                title: '競合店売価\n（会員価格）',
                className: 'text-center',
                isSort: true,
                field: 'competitor_selling_price',
                type: 'ASC',
            },
            {
                title: '開始日',
                className: 'text-center',
                isSort: true,
                field: 'sales_start_date',
                type: 'ASC',
            },
            {
                title: '終了日',
                isSort: true,
                field: 'sales_end_date',
                className: 'text-center',
                type: 'ASC',
            },
            {
                title: '競合対抗補填',
                isSort: true,
                className: 'text-center',
                type: 'ASC',
            },
            {
                title: '仕入止め',
                className: 'text-center',
                type: 'ASC',
            },
            {
                title: '選択',
                className: 'text-center',
            },
        ],
        body: {
            columns: [
                {
                    field: 'field_1',
                },
                {
                    field: 'field_2',
                },
                {
                    field: 'field_3',
                },
                {
                    field: 'field_4',
                },
                {
                    field: 'field_5',
                },
                {
                    field: 'field_6',
                },
                {
                    field: 'field_7',
                },
                {
                    field: 'field_8',
                },
                {
                    field: 'field_9',
                },
                {
                    field: 'field_10',
                },
                {
                    field: 'field_11',
                },
                {
                    field: 'checkbox_checked',
                    isInput: false,
                    isCheckbox: true,
                    label: 'remandReasonFlg',
                },
                {
                    field: 'checkbox_remand',
                    isInput: false,
                    isCheckbox: true,
                    label: 'confirmFlg',
                },
                {
                    field: 'field_12',
                },
            ],
        },
    };

    const renderTable = (type) => {
        return type === 'confirmTab' ? (
            <Table
                normal="true"
                ref={tableRef}
                total={61}
                heads={stateTableRequest.heads}
                body={stateTableRequest.body}
                dataTable={dataTableRequest}
                className="okc-table__parent"
                classNameTr="v-minHeight-46"
                isSelectRow={true}
                isMultiSelectRow={false}
                onSelectRow={(index, recordId) => {
                    handleSelectRow(index, recordId);
                }}
                dataTableSelect={dataTableSelect}
                handleClickSort={(field, type) => handleSort(1, field, type)}
                onChangeCheckList={(value) => console.log(value)}
                onChangePage={(page) => console.log(page)}
                pageProps={2}
            />
        ) : (
            <Table
                confirm="true"
                ref={tableRef}
                total={61}
                heads={stateTableConfirm.heads}
                body={stateTableConfirm.body}
                dataTable={dataTableConfirm}
                handleClickSort={(field, type) => handleSort(2, field, type)}
                onChangeCheckList={(value) => console.log(value)}
                onChangePage={(page) => console.log(page)}
                pageProps={2}
            />
        );
    };

    const handleTabTable = (event) => {
        setState((prevState) => ({
            ...prevState,
            isShowCard: event === 'mailConfirmTab' ? true : false,
        }));
    };

    const handleChange = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmitForm = () => {
        console.log('call api submit');
    };

    const handleClear = () => {
        console.log('clear');
    };

    return (
        <div className="price-request-competitive">
            {isShowCard && (
                <div className="okc-inner">
                    <Card handleSubmit={handleSubmitForm} handleClear={handleClear}>
                        <Label type="column" text={t('common.email')} className="col-lg-2 mb-2 mb-lg-4" />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <SelectSearch
                                onChange={(value) => {
                                    handleChange('option', value);
                                }}
                                options={[
                                    { value: '1', label: '一般食品１' },
                                    { value: '2', label: '一般食品２' },
                                    { value: '3', label: '日配食品１　' },
                                    { value: '4', label: '日配食品２　' },
                                    { value: '5', label: '冷凍食品・アイスクリ' },
                                    { value: '6', label: '乳製品・チルド飲料' },
                                    { value: '7', label: 'パン・和菓子・洋菓子' },
                                    { value: '8', label: '乳製品・チルド飲料' },
                                    { value: '9', label: 'パン・和菓子・洋菓子' },
                                ]}
                                value={option}
                            />
                        </div>
                        <Label type="column" text={t('common.email')} className="col-lg-2 mb-2 mb-lg-4" />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <SelectSearch
                                onChange={(value) => {
                                    handleChange('option', value);
                                }}
                                options={[
                                    { value: '1', label: '一般食品１' },
                                    { value: '2', label: '一般食品２' },
                                    { value: '3', label: '日配食品１　' },
                                    { value: '4', label: '日配食品２　' },
                                    { value: '5', label: '冷凍食品・アイスクリ' },
                                    { value: '6', label: '乳製品・チルド飲料' },
                                    { value: '7', label: 'パン・和菓子・洋菓子' },
                                    { value: '8', label: '乳製品・チルド飲料' },
                                    { value: '9', label: 'パン・和菓子・洋菓子' },
                                ]}
                                value={option}
                            />
                        </div>
                        <Label type="column" text={t('common.email')} className="col-lg-2 mb-2 mb-lg-4" style={{ height: '40px' }} />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <SelectSearch
                                onChange={(value) => {
                                    handleChange('option', value);
                                }}
                                options={[
                                    { value: '1', label: '一般食品１' },
                                    { value: '2', label: '一般食品２' },
                                    { value: '3', label: '日配食品１　' },
                                    { value: '4', label: '日配食品２　' },
                                    { value: '5', label: '冷凍食品・アイスクリ' },
                                    { value: '6', label: '乳製品・チルド飲料' },
                                    { value: '7', label: 'パン・和菓子・洋菓子' },
                                    { value: '8', label: '乳製品・チルド飲料' },
                                    { value: '9', label: 'パン・和菓子・洋菓子' },
                                ]}
                                value={option}
                            />
                        </div>
                    </Card>
                </div>
            )}
            <div id="pagination" className="d-flex c-slide-top">
                <nav className="okc-pagination okc-pagination--total ml-auto">
                    <p className="okc-pagination__total">全0件</p>
                </nav>
            </div>
            <div className="ok_tab_table">
                <Tabs
                    type="material"
                    tabs={[
                        {
                            title: '競合店売価登録 申請',
                            event: 'confirmTab',
                            content: renderTable('confirmTab'),
                        },
                        {
                            title: '近隣店舗 承認済一覧',
                            event: 'mailConfirmTab',
                            content: renderTable('mailConfirmTab'),
                        },
                    ]}
                    handleClick={(event) => handleTabTable(event)}
                />
                <Table
                    normal="true"
                    ref={tableRef}
                    total={61}
                    heads={stateTableRequest.heads}
                    body={stateTableRequest.body}
                    dataTable={dataTableRequest}
                    className="okc-table__parent"
                    isSelectRow={true}
                    isMultiSelectRow={false}
                    onSelectRow={(index, recordId) => {
                        handleSelectRow(index, recordId);
                    }}
                    dataTableSelect={dataTableSelect}
                    handleClickSort={(field, type) => handleSort(1, field, type)}
                    onChangeCheckList={(value) => console.log(value)}
                    onChangePage={(page) => console.log(page)}
                    pageProps={2}
                />
            </div>
            <div id="pagination_bottom" className="d-flex c-slide-top">
                <nav className="okc-pagination okc-pagination--total ml-auto">
                    <p className="okc-pagination__total">全0件</p>
                </nav>
            </div>
            <div className="d-flex justify-content-end c-slide-top mb-5">
                <Button id="submit-button" className="btn btn--primary mr-2 btn--disabled" disabled="disabled">
                    <span className="btn-text">申請</span>
                </Button>
                <Button id="delete-button" className="btn btn--primary btn--disabled" disabled="disabled">
                    <span className="btn-text">削除</span>
                </Button>
            </div>
            {dataTableSelect.length > 0 ? (
                <Table
                    ref={tableRef}
                    headerTitle="売価登録一覧"
                    colSpan="15"
                    heads={stateTableRequest.heads}
                    body={stateTableRequest.body}
                    dataTable={dataTableSelectRow}
                    isChildTable={true}
                    onDeleteRow={(indexRow) => handleDeleteRow(indexRow)}
                    onChangeCheckList={(value) => console.log(value)}
                    onChangePage={(page) => console.log(page)}
                    pageProps={2}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default DemoTablePage;
