import Modal from '../../common/Modal';
import Card from '../../common/Card';
import SelectSearch from '../../common/SelectSearch';
import { createCompetitorChainOptions } from '../../../utils/helpers/option';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinessFormats, fetchCompetitorChains, fetchCompetitorStores, setLoader } from '../../../redux/actions/api';
import { CompetitorMasterControllerService } from '../../../services';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import Input from '../../common/Input';
import apiErrorHandler from '../../../api/apiErrorHandler';

const DEFAULT_STATE = {
    selectedCompetitorChainCode: null,
    isNewChain: false,
    inputCompetitorChainName: '',
    inputCompetitorStoreName: '',
    selectedBusinessFormat: null,
};

const CompetitorCombinationMasterModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isShow, handleCloseModal } = props;

    const constants = useSelector((state) => state.constants);
    const { competitorChains, businessFormats } = constants;

    const [state, setState] = useState(DEFAULT_STATE);

    const { selectedCompetitorChainCode, isNewChain, inputCompetitorChainName, inputCompetitorStoreName, selectedBusinessFormat } = state;

    useEffect(() => {
        dispatch(fetchBusinessFormats());
    }, [dispatch]);

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const getChainName = (chainCode) => {
        return competitorChains.find((chain) => chain.competitorChainCode === chainCode)?.competitorChainName;
    };

    const handleSubmit = () => {
        if (validation()) return;

        dispatch(setLoader(true));
        const request = isNewChain
            ? {
                  chainName: inputCompetitorChainName,
                  businessFormat: selectedBusinessFormat,
                  competitorStoreName: `${inputCompetitorChainName}　${inputCompetitorStoreName}`,
              }
            : {
                  chainCode: selectedCompetitorChainCode,
                  competitorStoreName: `${getChainName(selectedCompetitorChainCode)}　${inputCompetitorStoreName}`,
              };
        CompetitorMasterControllerService.createCompetitorStore(request)
            .then(() => {
                dispatch(setToastsSuccess('競合店マスタを登録しました。'));
                setState(DEFAULT_STATE);
                dispatch(fetchCompetitorChains());
                dispatch(fetchCompetitorStores());
                handleCloseModal();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const validation = () => {
        if (isNewChain) {
            if (!inputCompetitorChainName) {
                dispatch(setToastsError('チェーン名を入力してください。'));
                return true;
            }
            if (!selectedBusinessFormat) {
                dispatch(setToastsError('業態を選択してください。'));
                return true;
            }
        } else {
            if (!selectedCompetitorChainCode) {
                dispatch(setToastsError('チェーンを選択してください。'));
                return true;
            }
        }
        if (!inputCompetitorStoreName) {
            dispatch(setToastsError('競合店名を入力してください。'));
            return true;
        }
        return false;
    };

    const handleResetAndCloseModal = () => {
        setState(DEFAULT_STATE);
        handleCloseModal();
    };

    return (
        <Modal
            size="xl"
            isShow={isShow}
            onClose={handleResetAndCloseModal}
            isShowIconClose="true"
            title={t('update_competitor_master.update-modal.header')}
            btnList={[
                {
                    name: t('update_competitor_master.update-modal.footer.primary-btn'),
                    className: 'btn--primary',
                    onClick: () => handleSubmit(),
                    disabled: false,
                },
                {
                    name: t('update_competitor_master.update-modal.footer.secondary-btn'),
                    className: 'btn--secondary',
                    onClick: () => handleResetAndCloseModal(),
                    disabled: false,
                },
            ]}
        >
            <div className="okc-card okc-result-form">
                <Card type="modal_card">
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('update_competitor_master.update-modal.form.chain_name.label')}</p>
                        </div>
                        <div className="col-lg-8 offset-lg-1 d-flex">
                            <div className="row w-100">
                                <div className="col-12 mb-2">
                                    {isNewChain ? (
                                        <>
                                            <Input
                                                className="form-control form-control--input chain-name-input"
                                                id="u-chain-name-input"
                                                type="text"
                                                placeholder={t('update_competitor_master.update-modal.form.chain_name.input.placeholder')}
                                                maxlength="40"
                                                autocomplete="off"
                                                value={inputCompetitorChainName}
                                                onChange={(value) => {
                                                    handleChangeState('inputCompetitorChainName', value);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <SelectSearch
                                            onChange={(option) => handleChangeState('selectedCompetitorChainCode', option.value)}
                                            options={createCompetitorChainOptions(competitorChains)}
                                            value={selectedCompetitorChainCode}
                                            placeholder={t('price_request.competitor_name.placeholder')}
                                        />
                                    )}
                                </div>
                                <div className="error-message-wrap">
                                    <p className="okc-error mt-2 mb-1 chain-name-error d-none">
                                        {t('competitor.name.is.already.registered')}
                                    </p>
                                </div>
                                <div className="col-12">
                                    <div
                                        className="form-check custom-checkbox master-update-modal__toggle-field js-switch-field"
                                        data-switch="1"
                                    >
                                        <input
                                            className="form-check-input"
                                            onChange={() => handleChangeState('isNewChain', !isNewChain)}
                                            type="checkbox"
                                            id={`isNewChain`}
                                            value={isNewChain}
                                            checked={isNewChain}
                                        />
                                        <label className="form-check-label" htmlFor={`isNewChain`}>
                                            {t('update_competitor_master.update-modal.form.chain_name.checkbox.register')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isNewChain && (
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title">
                                <p>{t('update_competitor_master.update-modal.form.business_format.label')}</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 flex-column">
                                <div className="row no-gutters w-100 js-okc-validate">
                                    {businessFormats?.map((option, index) => (
                                        <div key={index} className={`form-check custom-radio col-xl- col-lg-4 col-md-5`}>
                                            <input
                                                className={`${option.label ? 'bases__margin--right4' : ''} form-check-input`}
                                                type="radio"
                                                value={option.value}
                                                name={option.id}
                                                id={option.id}
                                                checked={selectedBusinessFormat === option.value}
                                                onChange={() => handleChangeState('selectedBusinessFormat', option.value)}
                                            />
                                            <label className="form-check-label" htmlFor={option.id}>
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('update_competitor_master.update-modal.form.competitive_store.label')}</p>
                        </div>
                        <div className="col-lg-8 offset-lg-1 d-flex">
                            <div className="col-auto competitor-store-prefix-wrap">
                                {(inputCompetitorChainName || selectedCompetitorChainCode) && (
                                    <div className="" id="competitor-store-prefix" style={{ marginBottom: '5px', marginRight: '15px' }}>
                                        {isNewChain ? inputCompetitorChainName : getChainName(selectedCompetitorChainCode)}
                                    </div>
                                )}
                            </div>
                            <div className="row no-gutters w-100">
                                <div className="col-12 mb-2 js-switch-target" data-switch="2">
                                    <Input
                                        className={`form-control form-control--input competitor-store-name-input`}
                                        id="u-store-name-input"
                                        type="text"
                                        placeholder={t('update_competitor_master.update-modal.form.competitive_store.input.placeholder')}
                                        maxlength="30"
                                        autocomplete="off"
                                        value={inputCompetitorStoreName}
                                        onChange={(value) => handleChangeState('inputCompetitorStoreName', value)}
                                    />
                                </div>
                                <p className="okc-error mt-2 mb-1 d-none chain-store-name-error">
                                    {t('competitor.name.is.already.registered')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Modal>
    );
};

export default CompetitorCombinationMasterModal;
