/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ProhibitedCategoryUpsertRequest = {
    departmentCode: number;
    lineCode?: number;
    classCode?: number;
    subclassCode?: number;
    lowestPriceType?: ProhibitedCategoryUpsertRequest.lowestPriceType;
    deleteFlag?: boolean;
};
export namespace ProhibitedCategoryUpsertRequest {
    export enum lowestPriceType {
        EDLP = 'EDLP',
        SARATOKU = 'SARATOKU',
        CHOME = 'CHOME',
        COMPETITOR = 'COMPETITOR',
        FRESHNESS = 'FRESHNESS',
        POS_CHANGED = 'POS_CHANGED',
        ORIGINAL_SELLING_PRICE_CHANGE = 'ORIGINAL_SELLING_PRICE_CHANGE',
        NULL = 'NULL',
        EMPTY = 'EMPTY',
    }
}

