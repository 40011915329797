/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ProhibitedItemSearchResult = {
    id?: number;
    jan?: string;
    itemName?: string;
    departmentCode?: number;
    departmentName?: string;
    sellingPrice?: number;
    lowerLimit?: number;
    quantityOfUnit?: number;
    capacityOfUnit?: number;
    lowestPriceType?: ProhibitedItemSearchResult.lowestPriceType;
    updatedAt?: string;
    startDate?: string;
    deleteFlag?: boolean;
};
export namespace ProhibitedItemSearchResult {
    export enum lowestPriceType {
        EDLP = 'EDLP',
        SARATOKU = 'SARATOKU',
        CHOME = 'CHOME',
        COMPETITOR = 'COMPETITOR',
        FRESHNESS = 'FRESHNESS',
        POS_CHANGED = 'POS_CHANGED',
        ORIGINAL_SELLING_PRICE_CHANGE = 'ORIGINAL_SELLING_PRICE_CHANGE',
        NULL = 'NULL',
        EMPTY = 'EMPTY',
    }
}

