import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/pages/stores/change-request-approved.css';
import SelectSearch from '../../common/SelectSearch';
import ReactCalender from '../../common/Datepicker';
import Card from '../../common/Card';
import Input from '../../common/Input';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import Label from '../../common/Label';
import Pagination from '../../common/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCompetitorChainsByStoreCode,
    fetchCompetitorStoresByStoreCode,
    fetchDivisions,
    fetchSources,
    setLoader,
} from '../../../redux/actions/api';
import { createCompetitorChainOptions, createCompetitorStoreOptions } from '../../../utils/helpers/option';
import EditTable from '../../tables/EditTable';
import { getTableChangeRequestApproved } from '../../tables/table';
import { ChangeRequestControllerService } from '../../../services';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import Choice from '../../common/Choice';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { setDeleteConfirm } from '../../../redux/actions/modal';

const ChangeRequestApproved = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const sort = useSelector((state) => state.sort);

    const constants = useSelector((state) => state.constants);
    const { divisions, competitorChains, competitorStores, sources } = constants;

    const storage = useSelector((state) => state.storage);
    const { isHeadquarter, changeRequestType } = storage;

    const modal = useSelector((state) => state.modal);
    const { isDeleteContinue } = modal;

    const [state, setState] = useState({
        departments: [],
        lines: [],
        classes: [],
        subclasses: [],
        selectedDivision: {},

        jan: '',
        startDate: '',
        endDate: '',
        selectedDepartment: '',
        selectedLineCode: '',
        selectedClassCode: '',
        selectedSubclassCode: '',
        competitorChainCode: '',
        competitorStoreCode: '',

        selectedSource: '',
        selectedStartDate: '',
        selectedEndDate: '',
        authorizerName: '',

        isShowPleaseSelect: false,
        isShowPleaseDeleteSelect: false,
        isShowModalApprove: false,

        page: 1,
        limit: 20,
        apiResponse: null,
    });

    const {
        departments,
        lines,
        classes,
        subclasses,

        jan,
        startDate,
        endDate,
        selectedDepartment,
        selectedLine,
        selectedClass,
        selectedSubclass,
        competitorChainCode,
        competitorStoreCode,

        selectedSource,
        selectedStartDate,
        selectedEndDate,
        authorizerName,

        isShowPleaseSelect,
        isShowPleaseDeleteSelect,
        isShowModalApprove,

        page,
        limit,
        apiResponse,
    } = state;

    const [stateDataDatePicker] = useState({
        minStartDate: new Date(),
        // 2日後の日付を取得
        maxStartDate: new Date(new Date().setDate(new Date().getDate() + 2)),
        minEndDate: new Date(),
        maxEndDate: '',
    });
    const { minStartDate, maxStartDate, minEndDate, maxEndDate } = stateDataDatePicker;

    useEffect(() => {
        dispatch(fetchDivisions());
        dispatch(fetchSources());
        if (!isHeadquarter) {
            dispatch(fetchCompetitorChainsByStoreCode());
            dispatch(fetchCompetitorStoresByStoreCode());
        }
    }, [dispatch]);

    useEffect(() => {
        getChangeRequestsApproved();
    }, [page, sort]);

    useEffect(() => {
        if (isDeleteContinue) {
            const selectedData = getSelectedData();
            ChangeRequestControllerService.deleteApprovedChangeRequest({
                items: selectedData.map((item) => {
                    return {
                        tokubaiProjectCode: item.tokubaiProjectCode,
                    };
                }),
            })
                .then(() => {
                    dispatch(setDeleteConfirm(false));
                    dispatch(setToastsSuccess('削除が完了しました。'));
                    getChangeRequestsApproved();
                })
                .catch((error) => {
                    apiErrorHandler(dispatch, error);
                });
        }
    }, [isDeleteContinue]);

    // 初期表示時に部門をセット
    useEffect(() => {
        if (divisions) {
            const departments = divisions
                .filter((item) => item.departmentCode > 0 && item.lineCode === 0 && item.classCode === 0 && item.subclassCode === 0)
                .map((item) => {
                    return {
                        ...item,
                        value: item.departmentCode,
                        label: item.divisionName,
                    };
                });
            setState((prevState) => ({
                ...prevState,
                departments,
            }));
        }
    }, [divisions]);

    // 部門が変更された場合にラインをセット
    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return item.departmentCode === selectedDepartment && item.lineCode > 0 && item.classCode === 0 && item.subclassCode === 0;
        });

        const lines = filteredDivisions.map((item) => {
            return {
                ...item,
                value: item.lineCode,
                label: item.divisionName,
            };
        });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            lines,
            selectedLine: null,
            selectedClass: null,
            selectedSubclass: null,
        }));
    }, [divisions, selectedDepartment]);

    // ラインが変更された場合にクラスをセット
    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return (
                item.departmentCode === selectedDepartment &&
                item.lineCode === selectedLine &&
                item.classCode > 0 &&
                item.subclassCode === 0
            );
        });

        const classes = filteredDivisions.map((item) => {
            return {
                ...item,
                value: item.classCode,
                label: item.divisionName,
            };
        });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            classes,
            selectedClass: null,
            selectedSubclass: null,
        }));
    }, [divisions, lines, selectedDepartment, selectedLine]);

    // クラスが変更された場合にサブクラスをセット
    useEffect(() => {
        const filteredDivisions = divisions?.filter((item) => {
            return (
                item.departmentCode === selectedDepartment &&
                item.lineCode === selectedLine &&
                item.classCode === selectedClass &&
                item.subclassCode > 0
            );
        });

        const subclasses = filteredDivisions.map((item) => {
            return {
                ...item,
                value: item.subclassCode,
                label: item.divisionName,
            };
        });

        setState((prevState) => ({
            ...prevState,
            filteredDivisions,
            subclasses,
            selectedSubclass: null,
        }));
    }, [classes, divisions, selectedClass, selectedDepartment, selectedLine]);

    const table = getTableChangeRequestApproved(t, isHeadquarter);

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleCloseModal = () => {
        setState((prevState) => ({
            ...prevState,
            isShowPleaseSelect: false,
            isShowPleaseDeleteSelect: false,
        }));
    };

    const getSelectedData = () => {
        return apiResponse?.data.filter((item) => item.selected) || [];
    };

    const handleContinuationRegister = () => {
        const selectedData = getSelectedData();
        if (selectedData.length === 0) {
            setState((prevState) => ({
                ...prevState,
                isShowPleaseSelect: true,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                isShowModalApprove: true,
            }));
        }
    };

    const handleDelete = () => {
        const selectedData = getSelectedData();
        if (selectedData.length === 0) {
            setState((prevState) => ({
                ...prevState,
                isShowPleaseDeleteSelect: true,
            }));
        } else {
            dispatch(setDeleteConfirm(true));
        }
    };

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const handleClear = () => {
        setState((prevState) => ({
            ...prevState,
            jan: '',
            startDate: '',
            endDate: '',
            selectedDepartment: '',
            selectedLineCode: '',
            selectedClassCode: '',
            selectedSubclassCode: '',
            competitorChainCode: '',
            competitorStoreCode: '',
        }));
    };

    const getChangeRequestsApproved = () => {
        dispatch(setLoader(true));
        // 検索
        ChangeRequestControllerService.getChangeRequestsApproved({
            changeRequestType: changeRequestType,
            departmentCode: selectedDepartment ? selectedDepartment : null,
            lineCode: selectedLine ? selectedLine : null,
            classCode: selectedClass ? selectedClass : null,
            subclassCode: selectedSubclass ? selectedSubclass : null,
            jan: jan ? jan : null,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            competitorChainCode: competitorChainCode ? competitorChainCode : null,
            competitorStoreCode: competitorStoreCode ? competitorStoreCode : null,
            page: page,
            limit: limit,
            sortField: table.heads.find((head) => head.field === sort.sortField)?.field,
            sortType: sort.sortType,
        })
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    apiResponse: response,
                }));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleCloseModalApprove = () => {
        setState((prevState) => ({
            ...prevState,
            isShowModalApprove: false,
            selectedSource: '',
            selectedStartDate: '',
            selectedEndDate: '',
            authorizerName: '',
        }));
    };

    const handleSubmitModalApprove = () => {
        if (selectedStartDate > selectedEndDate) {
            dispatch(setToastsError('終了日は開始日以降の日付を選択してください。'));
            return;
        }

        dispatch(setLoader(true));

        // 継続登録処理
        const selectedData = getSelectedData();
        ChangeRequestControllerService.createChangeRequestContinuation({
            source: selectedSource,
            salesStartDate: selectedStartDate,
            salesEndDate: selectedEndDate,
            authorizerName: authorizerName,
            items: selectedData.map((item) => {
                return {
                    jan: item.jan,
                    discountedRequestSellingPrice: item.discountedCompetitorSellingPrice,
                    competitorStoreCode: item.remarks,
                };
            }),
        })
            .then(() => {
                dispatch(setToastsSuccess('継続登録が完了しました。'));
                handleCloseModalApprove();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <>
            <div className="okc-inner">
                <Card handleSubmit={getChangeRequestsApproved} handleClear={handleClear}>
                    <Label type="column" text={t('approved_registration_price.search_form.jan.title')} className="col-lg-2 mb-2 mb-lg-4" />
                    <div className="col-lg-4 pr-lg-3 mb-4">
                        <Input
                            className="form-control form-control--input"
                            type="text"
                            value={jan}
                            maxLength="16"
                            placeholder={t('approved_registration_price.search_form.jan.placeholder')}
                            autocomplete="off"
                            onChange={(value) => {
                                handleChangeState('jan', value);
                            }}
                        />
                    </div>
                    <Label
                        type="column"
                        text={t('approved_registration_price.search_form.division_name.title')}
                        className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 mb-4" data-select2-id="select2-data-36-36wo">
                        <SelectSearch
                            options={departments}
                            placeholder={t('prohibited_product_list.search_form.division.placeholder')}
                            onChange={(target) => {
                                handleChangeState('selectedDivision', target);
                                handleChangeState('selectedDepartment', target.value);
                            }}
                            value={selectedDepartment}
                        />
                    </div>
                    <Label
                        type="column"
                        text={t('approved_registration_price.search_form.start_date.title')}
                        className="col-lg-2 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 pr-lg-3 mb-4">
                        <ReactCalender
                            value={startDate}
                            onChange={(value) => {
                                handleChangeState('startDate', value);
                            }}
                            classNameInputDateTime="text-left"
                        />
                    </div>
                    <Label
                        type="column"
                        text={t('approved_registration_price.search_form.line_name.title')}
                        className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 mb-4" data-select2-id="select2-data-65-e7mx">
                        <SelectSearch
                            options={lines}
                            placeholder={t('price_registration.search_form.line_name.placeholder')}
                            onChange={(target) => {
                                handleChangeState('selectedDivision', target);
                                handleChangeState('selectedLine', target.value);
                            }}
                            value={selectedLine}
                            disabled={!selectedDepartment}
                        />
                    </div>
                    <Label
                        type="column"
                        text={t('approved_registration_price.search_form.end_date.title')}
                        className="col-lg-2 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 pr-lg-3 mb-4">
                        <ReactCalender
                            value={endDate}
                            onChange={(value) => {
                                handleChangeState('endDate', value);
                            }}
                            classNameInputDateTime="text-left"
                        />
                    </div>
                    <Label
                        type="column"
                        text={t('approved_registration_price.search_form.class_name.title')}
                        className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 mb-4" data-select2-id="select2-data-87-0prc">
                        <SelectSearch
                            options={classes}
                            placeholder={t('price_registration.search_form.class_name.placeholder')}
                            onChange={(target) => {
                                handleChangeState('selectedDivision', target);
                                handleChangeState('selectedClass', target.value);
                            }}
                            value={selectedClass}
                            disabled={!selectedLine}
                        />
                    </div>
                    <Label
                        type="column"
                        text={t('approved_registration_price.search_form.competitor_chain.title')}
                        className="col-lg-2 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 pr-lg-3 mb-4" data-select2-id="select2-data-14-3y7t">
                        <SelectSearch
                            isSearch={false}
                            placeholder={t('export_summary.competitive_chain.placeholder')}
                            onChange={(option) => {
                                handleChangeState('competitorChainCode', option.value);
                            }}
                            options={createCompetitorChainOptions(competitorChains)}
                            value={competitorChainCode}
                            disabled={isHeadquarter}
                        />
                    </div>
                    <Label
                        type="column"
                        text={t('approved_registration_price.search_form.subclass_name.title')}
                        className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                    />
                    <div className="col-lg-4 mb-4" data-select2-id="select2-data-91-1ji8">
                        <SelectSearch
                            options={subclasses}
                            placeholder={t('price_registration.search_form.subclass_name.placeholder')}
                            onChange={(target) => {
                                handleChangeState('selectedDivision', target);
                                handleChangeState('selectedSubclass', target.value);
                            }}
                            value={selectedSubclass}
                            disabled={!selectedClass}
                        />
                    </div>
                    <div className="col-lg-6"></div>
                    <Label
                        type="column"
                        text={t('approved_registration_price.search_form.competitor_store.title')}
                        className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                        style={{ height: '40px' }}
                    />
                    <div className="col-lg-4 mb-4">
                        <SelectSearch
                            isSearch={false}
                            placeholder={t('export_summary.competitive_store.placeholder')}
                            onChange={(option) => {
                                handleChangeState('competitorStoreCode', option.value);
                            }}
                            options={createCompetitorStoreOptions(competitorStores, competitorChainCode)}
                            value={competitorStoreCode}
                            disabled={!competitorChainCode}
                        />
                    </div>
                </Card>
                <Pagination pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
                <div className="tab-pane show active">
                    <EditTable isHeightTr normal={!isHeadquarter} heads={table.heads} body={table.body} dataTable={apiResponse?.data} />
                    <div className="okc-delete d-flex justify-content-end mb-5">
                        <Button className="btn btn--primary upload-registration__submit" onClick={handleContinuationRegister}>
                            <span className="btn-text">継続登録</span>
                        </Button>
                        <Button className="btn btn--primary upload-registration__submit ml-3" onClick={handleDelete}>
                            <span className="btn-text">削除</span>
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                size="sm"
                type="please_select_checkbox"
                isShow={isShowPleaseSelect}
                onClose={handleCloseModal}
                isShowIconClose="true"
                title={t('approved_registration_price.modal.continue_registration.title')}
                btnList={[
                    {
                        name: t('button.OK'),
                        className: 'btn--primary',
                        onClick: () => handleCloseModal(),
                        disabled: false,
                    },
                ]}
            >
                <p className="text-center">{t('approved_registration_price.modal.continue_registration.body.text_03')}</p>
            </Modal>
            <Modal
                size="sm"
                type="please_select_checkbox"
                isShow={isShowPleaseDeleteSelect}
                onClose={handleCloseModal}
                isShowIconClose="true"
                title="選択した商品のデータを削除します。"
                btnList={[
                    {
                        name: t('button.OK'),
                        className: 'btn--primary',
                        onClick: () => handleCloseModal(),
                        disabled: false,
                    },
                ]}
            >
                <p className="text-center">削除するデータを選択してください。</p>
            </Modal>
            <Modal
                size="sm"
                isShow={isShowModalApprove}
                onClose={handleCloseModalApprove}
                isShowIconClose="true"
                title={'選択した商品を継続登録します。'}
                className="registration-edit-modal"
                btnList={[
                    {
                        name: t('confirm_registration_price.modal_confirm.button_ok'),
                        className: 'btn--primary',
                        onClick: () => handleSubmitModalApprove(),
                        disabled: !selectedSource || !selectedStartDate || !selectedEndDate || !authorizerName,
                    },
                    {
                        name: t('confirm_registration_price.modal_confirm.button_cancel'),
                        className: 'btn--secondary',
                        onClick: () => handleCloseModalApprove(),
                        disabled: false,
                    },
                ]}
            >
                <p className="text-center">選択した商品のデータを継続登録します。</p>
                <p className="text-center">情報取得元、開始日、終了日、承認者名を入力してください。</p>
                <div className="approval-source">
                    <Choice
                        id="source"
                        column="col-xl-3 col-lg-4 col-md-5"
                        type="radio"
                        data={sources}
                        onChange={(value) => handleChangeState('selectedSource', value[0])}
                        checked={[selectedSource]}
                    />
                </div>
                <div className="approval-date">
                    <ReactCalender
                        value={selectedStartDate}
                        onChange={(value) => handleChangeState('selectedStartDate', value)}
                        classNameInputDateTime="text-center"
                        minDate={minStartDate}
                        maxDate={maxStartDate}
                    />
                    <span className="approval-date__tilde">〜</span>
                    <ReactCalender
                        value={selectedEndDate}
                        onChange={(value) => handleChangeState('selectedEndDate', value)}
                        classNameInputDateTime="text-center"
                        minDate={minEndDate}
                        maxDate={maxEndDate}
                    />
                </div>
                <div className="approval-name">
                    <Input
                        className="form-control form-control--input"
                        name="authorizerName"
                        type="text"
                        autocomplete="off"
                        maxLength="45"
                        placeholder={t('confirm_registration_price.modal_confirm_deleted_csv.approval_name.label')}
                        value={authorizerName}
                        onChange={(value) => handleChangeState('authorizerName', value)}
                    />
                </div>
            </Modal>
        </>
    );
};

export default ChangeRequestApproved;
