import ReactCalender from '../../common/Datepicker';
import Input from '../../common/Input';
import { useEffect } from 'react';
import { getUnitPrice } from '../../../utils/helpers/formula';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../common/Checkbox';

const ModalProhibited = (props) => {
    const { t } = useTranslation();
    const { formData, setFormData } = props;

    console.log(formData);

    const { jan, itemName, deleteFlag, startDate, lowerLimit, lowerLimitUnit, sellingPrice, sellingUnitPrice } = formData;

    useEffect(() => {
        handleChangeOnBlur('lowerLimit', lowerLimit);
        handleChangeOnBlur('sellingPrice', sellingPrice);
    }, []);

    const handleChangeState = (field, value) => {
        setFormData((formData) => ({
            ...formData,
            [field]: value,
        }));
    };

    const handleChangeOnBlur = (field, value) => {
        if (field === 'lowerLimit') {
            setFormData((formData) => ({
                ...formData,
                lowerLimitUnit: getUnitPrice(value, formData.capacityOfUnit, formData.quantityOfUnit),
            }));
        } else {
            setFormData((formData) => ({
                ...formData,
                sellingUnitPrice: getUnitPrice(value, formData.capacityOfUnit, formData.quantityOfUnit),
            }));
        }
    };

    return (
        <div className="okc-modal__body modal-body">
            <h5 className="mb-2">{t('prohibited_product_list.edit_modal.product_information')}</h5>
            <div className="okc-card okc-result-form mb-5">
                <div className="okc-card__body">
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('prohibited_product_list.edit_modal.jan')}</p>
                        </div>
                        <div className="col-lg-8 offset-lg-1" data-target="origJan">
                            {jan}
                        </div>
                    </div>
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('prohibited_product_list.edit_modal.product_name')}</p>
                        </div>
                        <div className="col-lg-8 offset-lg-1" data-target="itemName">
                            {itemName}
                        </div>
                    </div>
                </div>
            </div>
            <h5 className="mb-2">{t('prohibited_product_list.edit_modal.disable_setting')}</h5>
            <div className="okc-card okc-result-form">
                <div className="okc-card__body">
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('prohibited_product_list.edit_modal.status.title')}</p>
                        </div>
                        <div className="col-lg-8 offset-lg-1 flex-column">
                            <div className="row no-gutters w-100 js-okc-validate">
                                <Checkbox
                                    column="col-xl-3 col-lg-4 col-md-5"
                                    id="delete-flag-edit"
                                    label={t('prohibited_product_list.edit_modal.status.label')}
                                    onChange={() => handleChangeState('deleteFlag', !deleteFlag)}
                                    value={deleteFlag}
                                    checked={deleteFlag}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p className="mb-0">{t('prohibited_product_list.edit_modal.start_date')}</p>
                        </div>
                        <div className="col-8 offset-lg-1 flex-column align-items-start js-okc-validate">
                            <ReactCalender
                                classNameInputDateTime="form-control--input-max-120"
                                value={startDate}
                                placeholder={t('prohibited_product_list.edit_modal.start_date')}
                                onChange={(value) => {
                                    handleChangeState('startDate', value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row okc-card__row">
                        <div className="col-lg-3 okc-result-form__title">
                            <p>{t('prohibited_product_list.edit_modal.lower_limit')}</p>
                        </div>
                        <div className="col-lg-8 offset-lg-1 d-flex">
                            <div className="row no-gutters w-100">
                                <div className="col-5">
                                    <div className="row no-gutters">
                                        <div className="col-12 mb-2">{t('prohibited_product_list.edit_modal.excluding_tax')}</div>
                                        <div className="col-12">
                                            <div className="row no-gutters">
                                                <div className="col">
                                                    <Input
                                                        className="form-control form-control--input"
                                                        value={lowerLimit}
                                                        onChange={(value) => handleChangeState('lowerLimit', value)}
                                                        maxlength="6"
                                                        id="input-2-1"
                                                        type="text"
                                                        autocomplete="off"
                                                        onBlur={(value) => handleChangeOnBlur('lowerLimit', value)}
                                                    />
                                                </div>
                                                <div className="col-auto px-2">/</div>
                                                <div className="col">
                                                    <Input
                                                        className="form-control form-control--input form-control--disabled"
                                                        value={lowerLimitUnit}
                                                        id="input-2-2"
                                                        type="text"
                                                        disabled={true}
                                                        autocomplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 offset-1">
                                    <div className="row no-gutters">
                                        <div className="col-12 mb-2">{t('prohibited_product_list.edit_modal.headquarters_price')}</div>
                                        <div className="col-12">
                                            <div className="row no-gutters">
                                                <div className="col">
                                                    <Input
                                                        className="form-control form-control--input form-control--disabled"
                                                        value={sellingPrice}
                                                        id="input-3-1"
                                                        type="text"
                                                        disabled={true}
                                                        autocomplete="off"
                                                        onBlur={(value) => handleChangeOnBlur('sellingPrice', value)}
                                                    />
                                                </div>
                                                <div className="col-auto px-2">/</div>
                                                <div className="col">
                                                    <Input
                                                        className="form-control form-control--input form-control--disabled"
                                                        value={sellingUnitPrice}
                                                        id="input-3-2"
                                                        type="text"
                                                        disabled={true}
                                                        autocomplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ModalProhibited;
