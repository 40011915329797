import React, { useEffect } from 'react';
import '../../assets/css/common/loader.css';
import { useSelector } from 'react-redux';

const Loader = () => {
    const { loader } = useSelector((states) => states);
    useEffect(() => {
        if (loader) {
            document.documentElement.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
        }
    }, [loader]);

    return loader ? (
        <div id="loading-component">
            <div className="loading"></div>
        </div>
    ) : (
        <></>
    );
};

export default Loader;
