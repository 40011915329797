import { Tabs as TabsBootstrap, Tab as TabBootstrap } from 'react-bootstrap';
import '../../assets/css/common/tab.css';

const Tabs = (props) => {
    const { tabs, handleClick } = props;

    return (
        <TabsBootstrap className="ok-navtabs__nav tab_nav" onSelect={(e) => handleClick(e)}>
            {tabs?.map((tab, index) => {
                return (
                    <TabBootstrap key={index} eventKey={tab.event} tabClassName="nav-link ok-navtabs__link" title={tab.title}>
                        {tab.content}
                    </TabBootstrap>
                );
            })}
        </TabsBootstrap>
    );
};

export default Tabs;
