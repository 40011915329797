import { useEffect, useState } from 'react';
import Card from '../../components/common/Card';
import Label from '../../components/common/Label';
import SelectSearch from '../../components/common/SelectSearch';
import ReactCalender from '../../components/common/Datepicker';
import Button from '../../components/common/Button';
import Pagination from '../../components/common/Pagination';
import '../../assets/css/pages/headquarters/update-competitor-master.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getTableUpdateCompetitorMaster } from '../../components/tables/table';
import {
    fetchCompetitorChains,
    fetchCompetitorStores,
    fetchDistances,
    fetchFrequencyRanks,
    fetchStores,
    setLoader,
} from '../../redux/actions/api';
import { createCompetitorChainOptions, createCompetitorStoreOptions, createStoreOptions } from '../../utils/helpers/option';
import { CompetitorMasterControllerService } from '../../services';
import EditTable from '../../components/tables/EditTable';
import CompetitorCombinationMasterModal from '../../components/layout/headquarters/CompetitorCombinationMasterModal';
import CompetitorStoreMasterModal from '../../components/layout/headquarters/CompetitorStoreMasterModal';
import apiErrorHandler from '../../api/apiErrorHandler';

const UpdateCompetitorMaster = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const sort = useSelector((state) => state.sort);

    const constants = useSelector((state) => state.constants);
    const { stores, competitorStores, competitorChains } = constants;

    const table = getTableUpdateCompetitorMaster(t);

    const [state, setState] = useState({
        isShowCompetitorStoreMasterModal: false,
        isShowCompetitorCombinationMasterModal: false,

        page: 1,
        limit: 20,
        apiResponse: null,
        requestedDate: '',
        selectedCompetitorChainCode: '',
        selectedCompetitorStoreCode: '',
        selectedStoreCode: '',
    });

    const {
        isShowCompetitorStoreMasterModal,
        isShowCompetitorCombinationMasterModal,

        page,
        limit,
        apiResponse,
        requestedDate,
        selectedCompetitorChainCode,
        selectedCompetitorStoreCode,
        selectedStoreCode,
    } = state;

    useEffect(() => {
        dispatch(fetchStores());
        dispatch(fetchCompetitorChains());
        dispatch(fetchCompetitorStores());
        dispatch(fetchDistances());
        dispatch(fetchFrequencyRanks());
    }, [dispatch]);

    useEffect(() => {
        getCompetitorMaster();
    }, [page, sort]);

    const handleSearchForm = () => {
        getCompetitorMaster();
    };

    const getCompetitorMaster = () => {
        dispatch(setLoader(true));
        CompetitorMasterControllerService.searchCompetitorCombinations(
            requestedDate ? requestedDate : null,
            selectedStoreCode ? selectedStoreCode : null,
            selectedCompetitorChainCode ? selectedCompetitorChainCode : null,
            selectedCompetitorStoreCode ? selectedCompetitorStoreCode : null,
            page,
            limit,
            table.heads.find((head) => head.field === sort.sortField)?.field,
            sort.sortType,
        )
            .then((response) => {
                handleChangeState('apiResponse', response);
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const handleClearForm = () => {
        setState((prevState) => ({
            ...prevState,
            requestedDate: '',
            selectedCompetitorChainCode: '',
            selectedCompetitorStoreCode: '',
            selectedStoreCode: '',
        }));
    };

    return (
        <div className="okc-container update-competitor-master">
            <div className="okc-inner">
                <div className="okc-accordion accordion fade-in">
                    <Card handleSubmit={handleSearchForm} handleClear={handleClearForm}>
                        <Label
                            type="column"
                            text={t('update_competitor_master.search_form.ok_store.text')}
                            className="col-lg-2 mb-2 mb-lg-4"
                        />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <SelectSearch
                                onChange={(option) => handleChangeState('selectedStoreCode', option.value)}
                                options={createStoreOptions(stores)}
                                value={selectedStoreCode}
                                placeholder={t('price_request.oke_store_name.placeholder')}
                            />
                        </div>
                        <Label
                            type="column"
                            text={t('update_competitor_master.search_form.registration_date.text')}
                            className="col-lg-2 pl-lg-3 mb-2 mb-lg-4"
                        />
                        <div className="col-lg-4 mb-4">
                            <ReactCalender
                                classNameInputDateTime="js-datepicker requested-date"
                                placeholder={t('update_competitor_master.search_form.registration_date.placeholder')}
                                value={requestedDate}
                                onChange={(value) => handleChangeState('requestedDate', value)}
                            />
                        </div>
                        <Label
                            type="column"
                            text={t('update_competitor_master.search_form.competitor_chain.text')}
                            className="col-lg-2 mb-2 mb-lg-4 v-base_height--40"
                        />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <SelectSearch
                                onChange={(option) => handleChangeState('selectedCompetitorChainCode', option.value)}
                                options={createCompetitorChainOptions(competitorChains)}
                                value={selectedCompetitorChainCode}
                                placeholder={t('price_request.competitor_name.placeholder')}
                            />
                        </div>
                        <Label type="column" className="col-lg-2 pl-lg-3 mb-2 mb-lg-4" />
                        <div className="col-lg-4 mb-4"></div>
                        <Label
                            type="column"
                            text={t('update_competitor_master.search_form.competitor_store.text')}
                            className="col-lg-2 mb-2 mb-lg-4 v-base_height--40"
                        />
                        <div className="col-lg-4 pr-lg-3 mb-4">
                            <SelectSearch
                                onChange={(option) => handleChangeState('selectedCompetitorStoreCode', option.value)}
                                options={createCompetitorStoreOptions(competitorStores, selectedCompetitorChainCode)}
                                value={selectedCompetitorStoreCode}
                                disabled={!selectedCompetitorChainCode}
                                placeholder={t('price_request.competitor_store_name.placeholder')}
                            />
                        </div>
                    </Card>
                </div>

                <div className="d-flex justify-content-between align-items-center fade-in">
                    <div className="d-flex">
                        <Button className="btn btn--primary" onClick={() => handleChangeState('isShowCompetitorStoreMasterModal', true)}>
                            <span className="btn-text">{t('update_competitor_master.button.master_update')}</span>
                        </Button>
                        <Button
                            className="btn btn--primary ml-2"
                            onClick={() => handleChangeState('isShowCompetitorCombinationMasterModal', true)}
                        >
                            <span className="btn-text">{t('update_competitor_master.button.registration')}</span>
                        </Button>
                    </div>
                    <div className="ml-auto">
                        <Pagination pageCount={apiResponse?.totalCount} onPageChange={handlePageClick} />
                    </div>
                </div>

                <EditTable isHeightTr heads={table.heads} body={table.body} dataTable={apiResponse?.data} />
            </div>

            <CompetitorStoreMasterModal
                isShow={isShowCompetitorStoreMasterModal}
                handleCloseModal={() => handleChangeState('isShowCompetitorStoreMasterModal', false)}
            />
            <CompetitorCombinationMasterModal
                isShow={isShowCompetitorCombinationMasterModal}
                handleCloseModal={() => handleChangeState('isShowCompetitorCombinationMasterModal', false)}
            />
        </div>
    );
};
export default UpdateCompetitorMaster;
