import { initializeApp } from 'firebase/app';
import { OpenAPI } from './services';
import env from './configs/env';

const init = () => {
    OpenAPI.BASE = env.BACKEND_BASE_URL;
    OpenAPI.WITH_CREDENTIALS = true;

    // APIキーが設定されている場合はFirebaseを初期化する
    if (env.FIREBASE_API_KEY) {
        const firebaseConfig = {
            apiKey: env.FIREBASE_API_KEY,
            authDomain: 'ok-competitive.firebaseapp.com',
            projectId: 'ok-competitive',
            storageBucket: 'ok-competitive.appspot.com',
            messagingSenderId: '108960084721',
            appId: env.ENV === 'prd' ? '1:108960084721:web:446060a454d3f83b762f50' : '1:108960084721:web:b908207a4d0a7227762f50',
            measurementId: 'G-8CZRRTBCTC',
        };
        initializeApp(firebaseConfig);
    }
};

export default init;
