import ChangeRequest from '../../components/layout/stores/ChangeRequest';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setChangeRequestType } from '../../redux/actions/storage';
import { useDispatch, useSelector } from 'react-redux';
import { PriceSearchRequest } from '../../services';
import { useEffect } from 'react';
import ChangeRequestApprove from '../../components/layout/stores/ChangeRequestApprove';
import ChangeRequestApproved from '../../components/layout/stores/ChangeRequestApproved';

const CONFIRM_STATUS = ['REQUESTING', 'NOT_CONFIRM', 'CONFIRMED', 'REMAND'];

const ChangeRequestPage = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const { confirmStatus } = useParams();
    const confirmStatusUpper = confirmStatus.toUpperCase();

    const storage = useSelector((state) => state.storage);
    const { isEditor } = storage;

    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const confirmRequestId = query.get('confirmRequestId');

    if (confirmRequestId) {
        dispatch(setChangeRequestType(PriceSearchRequest.changeRequestType.COMPETITOR));
    }

    useEffect(() => {
        if (!CONFIRM_STATUS.includes(confirmStatusUpper)) {
            history('/change-request/requesting');
        }
    }, [confirmStatusUpper, history]);

    switch (confirmStatusUpper) {
        case 'REQUESTING':
        case 'REMAND':
            return <ChangeRequest confirmStatus={confirmStatusUpper} confirmRequestId={confirmRequestId} />;
        case 'NOT_CONFIRM':
            if (isEditor) {
                history('/change-request/requesting');
                return;
            }
            return <ChangeRequestApprove />;
        case 'CONFIRMED':
            return <ChangeRequestApproved />;
        default:
            return <></>;
    }
};

export default ChangeRequestPage;
