export const minRequestedDateMin = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
};

export const minStartDateMin = () => {
    let minStartDateMin = new Date();
    return minStartDateMin.setDate(new Date().getDate() - 14);
};

export const maxStartDateMin = () => {
    let maxStartDateMin = new Date();
    return maxStartDateMin.setDate(new Date().setDate() + 14);
};

export const maxEndDateMin = () => {
    let maxEndDateMin = new Date();
    return maxEndDateMin.setDate(new Date().setDate() + 14);
};

export const formattedDate = (timestamp) => {
    let date = new Date(timestamp);
    return date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2);
};
