import {
    FETCH_BUSINESS_FORMAT,
    FETCH_COMPETITOR_CHAINS,
    FETCH_COMPETITOR_STORES,
    FETCH_DEPARTMENTS,
    FETCH_DISTANCES,
    FETCH_DIVISIONS,
    FETCH_EXPAND_SALES_FLOORS,
    FETCH_FREQUENCY_RANKS,
    FETCH_ITEM_CATEGORIES,
    FETCH_PACKING_CATEGORIES,
    FETCH_REGISTRATION_METHODS,
    FETCH_SALE_TYPES,
    FETCH_SOURCES,
    FETCH_STORES,
    FETCH_USER_ORGANIZATION,
    SET_LOADER,
} from './type';
import { CompetitorMasterControllerService, ConstantsControllerService } from '../../services';
import apiErrorHandler from '../../api/apiErrorHandler';

export const setLoader = (data = false) => {
    return {
        type: SET_LOADER,
        data,
    };
};

export const fetchData = (apiCall, successActionType, isLoad = true) => {
    return async (dispatch) => {
        if (isLoad) {
            dispatch(setLoader(true));
        }

        apiCall()
            .then((response) => {
                dispatch({ type: successActionType, payload: response });
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                if (isLoad) {
                    dispatch(setLoader(false));
                }
            });
    };
};

export const shouldFetch = (existingData) => {
    return !existingData || existingData.length === 0;
};

export const fetchSources = (isLoad = true) => {
    return (dispatch, getState) => {
        const { sources } = getState().constants;
        if (shouldFetch(sources)) {
            fetchData(ConstantsControllerService.getSources, FETCH_SOURCES, isLoad)(dispatch);
        }
    };
};

export const fetchRegistrationMethods = (isLoad = true) => {
    return (dispatch, getState) => {
        const { registrationMethods } = getState().constants;
        if (shouldFetch(registrationMethods)) {
            fetchData(ConstantsControllerService.getRegistrationMethods, FETCH_REGISTRATION_METHODS, isLoad)(dispatch);
        }
    };
};

export const fetchDepartments = (isLoad = true) => {
    return (dispatch, getState) => {
        const { departments } = getState().constants;
        if (shouldFetch(departments)) {
            fetchData(ConstantsControllerService.getDepartments, FETCH_DEPARTMENTS, isLoad)(dispatch);
        }
    };
};

export const fetchDivisions = (isLoad = true) => {
    return (dispatch, getState) => {
        const { divisions } = getState().constants;
        if (shouldFetch(divisions)) {
            fetchData(ConstantsControllerService.getDivisions, FETCH_DIVISIONS, isLoad)(dispatch);
        }
    };
};

export const fetchStores = (isLoad = true) => {
    return (dispatch, getState) => {
        const { competitorStores } = getState().constants;
        if (shouldFetch(competitorStores)) {
            fetchData(ConstantsControllerService.getStores, FETCH_STORES, isLoad)(dispatch);
        }
    };
};

export const fetchCompetitorChains = (isLoad = true) => {
    return (dispatch) => {
        fetchData(CompetitorMasterControllerService.getCompetitorChains, FETCH_COMPETITOR_CHAINS, isLoad)(dispatch);
    };
};

export const fetchCompetitorChainsByStoreCode = (isLoad = true) => {
    return (dispatch, getState) => {
        const { competitorStores } = getState().constants;
        if (shouldFetch(competitorStores)) {
            fetchData(ConstantsControllerService.getCompetitorChainsByStoreCode, FETCH_COMPETITOR_CHAINS, isLoad)(dispatch);
        }
    };
};

export const fetchCompetitorStores = (isLoad = true) => {
    return (dispatch) => {
        fetchData(CompetitorMasterControllerService.getCompetitorStores, FETCH_COMPETITOR_STORES, isLoad)(dispatch);
    };
};

export const fetchCompetitorStoresByStoreCode = (isLoad = true) => {
    return (dispatch, getState) => {
        const { competitorStores } = getState().constants;
        if (shouldFetch(competitorStores)) {
            fetchData(ConstantsControllerService.getCompetitorStoresByStoreCode, FETCH_COMPETITOR_STORES, isLoad)(dispatch);
        }
    };
};

export const fetchItemCategories = (isLoad = true) => {
    return (dispatch, getState) => {
        const { itemCategories } = getState().constants;
        if (shouldFetch(itemCategories)) {
            fetchData(ConstantsControllerService.getItemCategories, FETCH_ITEM_CATEGORIES, isLoad)(dispatch);
        }
    };
};

export const fetchExpandSalesFloors = (isLoad = true) => {
    return (dispatch, getState) => {
        const { expandSalesFloors } = getState().constants;
        if (shouldFetch(expandSalesFloors)) {
            fetchData(ConstantsControllerService.getExpandSalesFloors, FETCH_EXPAND_SALES_FLOORS, isLoad)(dispatch);
        }
    };
};

export const fetchPackingCategories = (isLoad = true) => {
    return (dispatch, getState) => {
        const { packingCategories } = getState().constants;
        if (shouldFetch(packingCategories)) {
            fetchData(ConstantsControllerService.getPackingCategories, FETCH_PACKING_CATEGORIES, isLoad)(dispatch);
        }
    };
};

export const fetchSaleTypes = (isLoad = true) => {
    return (dispatch, getState) => {
        const { saleTypes } = getState().constants;
        if (shouldFetch(saleTypes)) {
            fetchData(ConstantsControllerService.getSaleTypes, FETCH_SALE_TYPES, isLoad)(dispatch);
        }
    };
};

export const fetchDistances = (isLoad = true) => {
    return (dispatch, getState) => {
        const { distances } = getState().constants;
        if (shouldFetch(distances)) {
            fetchData(ConstantsControllerService.getDistances, FETCH_DISTANCES, isLoad)(dispatch);
        }
    };
};

export const fetchFrequencyRanks = (isLoad = true) => {
    return (dispatch, getState) => {
        const { frequencyRanks } = getState().constants;
        if (shouldFetch(frequencyRanks)) {
            fetchData(ConstantsControllerService.getFrequencyRanks, FETCH_FREQUENCY_RANKS, isLoad)(dispatch);
        }
    };
};

export const fetchBusinessFormats = (isLoad = true) => {
    return (dispatch, getState) => {
        const { businessFormat } = getState().constants;
        if (shouldFetch(businessFormat)) {
            fetchData(ConstantsControllerService.getBusinessFormats, FETCH_BUSINESS_FORMAT, isLoad)(dispatch);
        }
    };
};

export const fetchUserOrganizations = (isLoad = true) => {
    return (dispatch, getState) => {
        const { userOrganization } = getState().constants;
        if (shouldFetch(userOrganization)) {
            fetchData(ConstantsControllerService.getUserOrganizations, FETCH_USER_ORGANIZATION, isLoad)(dispatch);
        }
    };
};
