import {
    FETCH_BUSINESS_FORMAT,
    FETCH_COMPETITOR_CHAINS,
    FETCH_COMPETITOR_STORES,
    FETCH_DEPARTMENTS,
    FETCH_DISTANCES,
    FETCH_DIVISIONS,
    FETCH_EXPAND_SALES_FLOORS,
    FETCH_FREQUENCY_RANKS,
    FETCH_ITEM_CATEGORIES,
    FETCH_PACKING_CATEGORIES,
    FETCH_REGISTRATION_METHODS,
    FETCH_SALE_TYPES,
    FETCH_SOURCES,
    FETCH_STORES,
    FETCH_USER_ORGANIZATION,
    SET_LOADER,
} from '../actions/type';

const loaderReducer = (state = false, action) => {
    switch (action.type) {
        case SET_LOADER:
            return action.data;
        default:
            return state;
    }
};

const initialState = {
    sources: [],
    registrationMethods: [],
    departments: [],
    divisions: [],
    stores: [],
    competitorChains: [],
    competitorStores: [],
    itemCategories: [],
    expandSalesFloors: [],
    packingCategories: [],
    saleTypes: [],
    distances: [],
    frequencyRanks: [],
    businessFormats: [],
    userOrganizations: [],
};

const constantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SOURCES:
            return {
                ...state,
                sources: action.payload,
            };
        case FETCH_REGISTRATION_METHODS:
            return {
                ...state,
                registrationMethods: action.payload,
            };
        case FETCH_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload,
            };
        case FETCH_DIVISIONS:
            return {
                ...state,
                divisions: action.payload,
            };
        case FETCH_STORES:
            return {
                ...state,
                stores: action.payload,
            };
        case FETCH_COMPETITOR_CHAINS:
            return {
                ...state,
                competitorChains: action.payload,
            };
        case FETCH_COMPETITOR_STORES:
            return {
                ...state,
                competitorStores: action.payload,
            };
        case FETCH_ITEM_CATEGORIES:
            return {
                ...state,
                itemCategories: action.payload,
            };
        case FETCH_EXPAND_SALES_FLOORS:
            return {
                ...state,
                expandSalesFloors: action.payload,
            };
        case FETCH_PACKING_CATEGORIES:
            return {
                ...state,
                packingCategories: action.payload,
            };
        case FETCH_SALE_TYPES:
            return {
                ...state,
                saleTypes: action.payload,
            };
        case FETCH_DISTANCES:
            return {
                ...state,
                distances: action.payload,
            };
        case FETCH_FREQUENCY_RANKS:
            return {
                ...state,
                frequencyRanks: action.payload,
            };
        case FETCH_BUSINESS_FORMAT:
            return {
                ...state,
                businessFormats: action.payload,
            };
        case FETCH_USER_ORGANIZATION:
            return {
                ...state,
                userOrganizations: action.payload,
            };
        default:
            return state;
    }
};

export { loaderReducer, constantsReducer };
