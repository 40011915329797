import logo from '../assets/images/logo.svg';
import '../assets/css/pages/select-menu.css';
import Image from '../components/common/Image';
import { useTranslation } from 'react-i18next';
import Button from '../components/common/Button';
import { useNavigate } from 'react-router-dom';
import { PriceSearchRequest } from '../services';
import { useDispatch } from 'react-redux';
import { setChangeRequestType } from '../redux/actions/storage';

const SelectMenu = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const dispatch = useDispatch();

    const handleRequestAction = (action) => {
        dispatch(setChangeRequestType(action));
        switch (action) {
            case PriceSearchRequest.changeRequestType.COMPETITOR:
                history('/survey-history');
                break;
            case PriceSearchRequest.changeRequestType.FRESHNESS:
            case PriceSearchRequest.changeRequestType.INSTRUCTION:
                history('/select-store');
                break;
            default:
                break;
        }
    };

    return (
        <div className="row no-gutters">
            <div className="col-12 col-md-6 col-lg-4 m-auto">
                <div className="select-menu">
                    <div className="select-menu__inner">
                        <div className="select-menu__logo fade-in">
                            <Image src={logo} />
                        </div>
                        <div className="select-menu__form c-slide-top">
                            <div className="form-title">{t('select_menu.title')}</div>
                            <Button
                                type="button"
                                className="form-option"
                                onClick={() => handleRequestAction(PriceSearchRequest.changeRequestType.COMPETITOR)}
                                text={t('select_menu.option.competitor_store')}
                            />
                            <Button
                                type="button"
                                className="form-option"
                                onClick={() => handleRequestAction(PriceSearchRequest.changeRequestType.FRESHNESS)}
                                text={t('select_menu.option.freshness')}
                            />
                            <Button
                                type="button"
                                className="form-option"
                                onClick={() => handleRequestAction(PriceSearchRequest.changeRequestType.INSTRUCTION)}
                                text={t('select_menu.option.competition_registration_management')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectMenu;
