import {
    SET_SORT,
    SET_TYPE_ERROR,
    SET_TYPE_EXCEL,
    SET_TYPE_FLYER,
    SET_TYPE_NO_EXCEL,
    SET_TYPE_STORE,
    SET_TYPE_WARNING,
} from '../actions/type';

const toastReducer = (state = false, action) => {
    switch (action.type) {
        case SET_TYPE_WARNING:
            return action;
        case SET_TYPE_ERROR:
            return action;
        default:
            return action;
    }
};

const typeRegisterReducer = (state = false, action) => {
    switch (action.type) {
        case SET_TYPE_EXCEL:
            return action.type;
        case SET_TYPE_NO_EXCEL:
            return action.type;
        default:
            return state;
    }
};

const typeSourceInformationReducer = (state = false, action) => {
    switch (action.type) {
        case SET_TYPE_FLYER:
            return action.type;
        case SET_TYPE_STORE:
            return action.type;
        default:
            return state;
    }
};

const sortReducer = (
    state = {
        sortField: null,
        sortType: 'ASC',
    },
    action,
) => {
    switch (action.type) {
        case SET_SORT:
            return action.sort;
        default:
            return state;
    }
};

export { toastReducer, typeRegisterReducer, typeSourceInformationReducer, sortReducer };
