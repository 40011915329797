/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ApprovedChangeRequestSearchRequest = {
    page?: number;
    limit?: number;
    sortField?: string;
    sortType?: ApprovedChangeRequestSearchRequest.sortType;
    changeRequestType?: ApprovedChangeRequestSearchRequest.changeRequestType;
    departmentCode?: number;
    lineCode?: number;
    classCode?: number;
    subclassCode?: number;
    jan?: string;
    startDate?: string;
    endDate?: string;
    competitorChainCode?: string;
    competitorStoreCode?: string;
    remarks?: string;
};
export namespace ApprovedChangeRequestSearchRequest {
    export enum sortType {
        ASC = 'ASC',
        DESC = 'DESC',
    }
    export enum changeRequestType {
        COMPETITOR = 'COMPETITOR',
        FRESHNESS = 'FRESHNESS',
        INSTRUCTION = 'INSTRUCTION',
    }
}

